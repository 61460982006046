export const brandBlue = '#001A3E';
export const brandAmber = '#EBB203';
export const brandRed = '#8F0018';
export const brandDarkGrey = '#5E5F64';
export const brandLightGrey = '#E9E9E9';
export const brandLightGreyText = '#CBCED1';
export const brandVeryLightGrey = '#FAFAFA';
export const leftMenu = 'rgba(0, 0, 0, 0.6)';
export const standardWhite = '#FFFFFF';
export const smokeWhite = '#F5F5F5';
export const standardShadow = 'rgba(0, 0, 0, 0.16) 0px 4px 6px';
