import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchFailed, fetchStart } from '../helpers';
import { AccountState } from './types';

const accountInitialState: AccountState = {
  sessionKey: null,
  isLoading: false,
  error: null,
  showInvitationModal: true,
};

const account = createSlice({
  name: 'account',
  initialState: accountInitialState,
  reducers: {
    accountSliceReset: () => accountInitialState,
    fetchAccountStart: fetchStart,
    fetchAccountFailed: fetchFailed,
    loginSuccess(state, { payload }: PayloadAction<string>) {
      state.sessionKey = payload;
      state.isLoading = false;
      state.error = null;
    },
    checkSessionSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    checkSessionFailed(state, { payload }: PayloadAction<string | null>) {
      state.sessionKey = null;
      state.isLoading = false;
      state.error = payload;
    },
    invitationModalDisabled(state) {
      state.showInvitationModal = false;
    },
  },
});

export const accountActions = account.actions;

export default account.reducer;
