import React from 'react';

const DPA = (): JSX.Element => {
  return (
    <div>
      <b>LINK</b>
      <br />
      <br />
      <b>Vessel Management Eco-System</b>
      <br />
      <br />
      <b>DATA PROCESSING AGREEMENT (DPA)</b>
      <br />
      <br />
      1. General
      <br />
      <br />
      For the data processing activities described in the respective Appendix of this agreement,
      where LINK acts as the Customer&#39;s Processor, the parties agree to the following provisions
      on the commissioned processing of personal data, which shall supplement the LINK End User
      License Agreement (EULA) (Data Processing Agreement, &quot;DPA&quot;) until further notice.
      <br />
      <br />
      The DPA does not apply if the Customer is a natural person using the Software or the Services
      in the course of a purely personal or family activity (cf. Art. 2(2)(c) EU General Data
      Protection Regulation, &quot;GDPR&quot;).
      <br />
      <br />
      The provisions of this DPA and the EULA concluded at the same time complement each other and
      exist side by side. In the event of any contradictions in the area of data protection, the DPA
      shall take precedence over the provisions of the EULA.
      <br />
      <br />
      1. Rights and obligations of LINK
      <br />
      <br />
      2.1. Compliance with applicable laws
      <br />
      <br />
      The obligations of LINK shall arise from this DPA and the applicable laws. The applicable laws
      shall in particular include the Data Protection Act (&quot;DPA&quot;) and the GDPR.
      <br />
      <br />
      2.2. Processing on instructions only
      <br />
      <br />
      To the extent this DPA is applicable and practically feasible, LINK shall only process
      personal data within the scope of this DPA and on documented instructions of the Customer,
      which are mutually agreed upon by the parties in the EULA and especially defined by the
      Product functionality, unless LINK is required to do so by UK law; in such a case LINK shall
      inform the Customer of that legal requirement before processing, unless the respective law
      prohibits such information on important grounds of public interest. The Customer can give
      additional written instructions as far as this is necessary to comply with the applicable data
      protection law. The documentation on issued instructions shall be kept by the Customer for the
      term of the DPA.
      <br />
      <br />
      2.3. Obligation of confidentiality
      <br />
      <br />
      LINK shall ensure that the persons authorized to process the personal data have committed
      themselves to confidentiality unless they are subject to an appropriate legal obligation of
      secrecy.
      <br />
      <br />
      2.4. Security measures according to Art. 32 DS-GVO
      <br />
      <br />
      2.4.1. Principle
      <br />
      <br />
      LINK will take necessary measures towards ensuring the security of the processing according to
      Article 32 GDPR (hereinafter referred to as &quot;Security Measures&quot;).
      <br />
      <br />
      2.4.2. Scope
      <br />
      <br />
      For the specific commissioned processing of personal data, a level of security appropriate to
      the risk to the rights and freedoms of the natural persons affected by the processing shall be
      guaranteed. To this end, the protection objectives of Art.32 (1) GDPR, such as
      confidentiality, integrity and availability of systems and services and their resilience in
      terms of the nature, scope, as well as context of the processing shall be taken into account
      in such a way that the risks are mitigated permanently by appropriate Security Measures.
      <br />
      <br />
      2.4.3. Security Measures
      <br />
      <br />
      LINK does not make public all details of security measures as that in itself could present a
      security risk. Details of the adopted Security Measures are available on request via
      contacting LINK&#39;s Data Protection Officer.
      <br />
      <br />
      2.4.4. Procedure for reviewing
      <br />
      <br />
      LINK regularly reviews and evaluates of the effectiveness of the then-current Security
      Measures. This is typically achieved via internal peer review, product testing, and external
      security penetration testing.
      <br />
      <br />
      2.4.5. Changes
      <br />
      <br />
      The Security Measures are subject to technical progress and further development. LINK shall be
      generally permitted to implement alternative appropriate measures. In doing so, the level of
      security may not fall below the level existing prior to this DPA on the basis of the Security
      Measures already implemented or to be implemented.
      <br />
      <br />
      2.5. Assistance with safeguarding the rights of data subjects
      <br />
      <br />
      LINK shall, taking into account the nature of the processing, assist the Customer as far as
      this is possible by appropriate technical and organizational measures in the fulfilment of
      requests to exercise the rights of affected data subjects as referred in Chapter III of the
      GDPR. Should a data subject contact LINK directly to exercise the data subject&#39;s rights
      regarding the data processed on behalf of the Customer (as far as identifiable), LINK shall
      immediately forward such request to the Customer. The Customer shall remunerate LINK an hourly
      rate of 100 GBP for the effort resulting from such assistance, if and as far as permitted by
      applicable data protection laws.
      <br />
      <br />
      2.6. Assistance with ensuring compliance with Art. 32 - 36 GDPR
      <br />
      <br />
      Taking into account the type of processing and the information available to LINK, on request,
      LINK shall support the Customer with appropriate technical and organizational measures to
      comply with the obligations mentioned in Article 32-36 GDPR, especially with regard to the
      security of the processing, the notification of personal data breach, the data protection
      impact assessment as well as the consultation with supervisory authorities. The Customer shall
      remunerate LINK an hourly rate of 100 GBP for the effort resulting from such assistance, if
      and as far as permitted by applicable data protection laws.
      <br />
      <br />
      2.7. Records of processing activities
      <br />
      <br />
      On request, LINK will provide the Customer with the information necessary to maintain the
      records of processing activities, subject to LINK&#39;s data retention policies.
      <br />
      <br />
      2.8. Deletion and return at the end of processing
      <br />
      <br />
      On request, at the choice of the Customer, LINK shall return the personal data that is
      processed on behalf of the Customer, if and to the extent that the law of England and Wales
      does not provide for an obligation to store the data. In other scenarios, LINK shall
      automatically delete data, if it has fallen outside of the scope of LINK&#39;s data retention
      policy.
      <br />
      <br />
      2.9. Information to demonstrate compliance with data protection obligations and inspections
      <br />
      <br />
      On request, LINK shall provide the customer with all information necessary to demonstrate
      compliance with the obligations resulting from Sections 2 and 3 of this DPA.
      <br />
      <br />
      If and insofar there are objectively justified indications of a violation of this DPA or of
      data protection regulations by LINK, LINK will enable and contribute to audits, including
      inspections, which are carried out by the Customer or by a qualified auditor appointed by the
      Customer. When conducting the inspection, the Customer will not disrupt LINK&#39;s operations
      in a disproportionate manner.
      <br />
      <br />
      2.10. Obligation to notify in case of doubts about instructions
      <br />
      <br />
      LINK shall inform the Customer immediately if LINK is of the opinion that the execution of an
      instruction could lead to a violation of the applicable data protection law. LINK is entitled
      to suspend the execution of the relevant instruction until it is confirmed in writing or
      changed by the Customer after the review.
      <br />
      <br />
      2.11. Obligation to notify breaches
      <br />
      <br />
      If LINK detects violations of the applicable data protection law, this DPA, or instructions of
      the Customer regarding the commissioned processing of personal data, LINK shall inform the
      Customer immediately.
      <br />
      <br />
      2.12. Appointment of a data protection officer
      <br />
      <br />
      LINK&#39;s data protection officer, can be reached at gdpr@smartyachts.link, or via post
      marked Data FAO Protection Officer LINK – LINK Imperium Ltd, The Maltings, Allendale, Hexham,
      Northumberland, NE47 9EE, United Kingdom.
      <br />
      <br />
      2.13. Data transfers to a third country
      <br />
      <br />
      LINK will generally only transfer personal data processed within the scope of this DPA to a
      country outside the UK, EU or the European Economic Area (EEA) for which no adequacy decision
      of the EU Commission in the sense of Art. 45 para. 3 GDPR exists (&quot;unsafe third
      country&quot;), provided that:
      <br />
      <br />
      a. the Customer or the Customer&#39;s user gives LINK instructions for such a transfer,
      _e.g._, by requesting a connection to an endpoint located in an unsafe third country (in such
      cases the Customer is responsible for ensuring that the data transfer is carried out in
      accordance with Art. 44 et seq. GDPR), or
      <br />
      <br />
      b. LINK is obliged to do so according to the law of England and Wales; in such a case LINK
      will inform the Customer about these legal requirements prior to processing, unless the
      respective law prohibits such a communication on important grounds of public interest.
      <br />
      <br />
      Furthermore, LINK shall be entitled to utilize Subprocessors in a third country to process
      personal data, providing that the requirements of Art. 44 GDPR are met.
      <br />
      <br />
      3. Subprocessors
      <br />
      <br />
      3.1. Subprocessors engaged upon conclusion of the DPA
      <br />
      <br />
      LINK may utilize the services of a number of another processors (hereinafter,
      &quot;Subprocessors&quot;) for agreed activities such as secure data hosting. The current list
      of Subprocessors used by LINK can be obtained at any point by contacting the Data Protection
      Officer. Customer&#39;s can request to be notified in the event of a change of Subprocessors.
      <br />
      <br />
      4. Changes to this DPA
      <br />
      <br />
      LINK is generally entitled to amend the provisions of this DPA. LINK will inform the Customer
      about the planned change and the content of the new DPA at least twenty-eight (28) days before
      such changes become effective. The change is considered approved if the Customer does not
      object to LINK within fifteen (15) days after receipt of this information. If the Customer
      objects to the change, the DPA continues under the existing conditions.
      <br />
      <br />
      5. Liability
      <br />
      <br />
      Reference is made to Art. 82 of the GDPR.
      <br />
      <br />
      For the rest, it is agreed that the regulations on limitation of liability from the
      corresponding license agreement shall apply.
      <br />
      <br />
      9th March 2021
      <br />
      <br />
      Appendix I – Details of Data Processing in the LINK Eco System
      <br />
      <br />
      1. Nature and purpose of the processing
      <br />
      <br />
      LINK will process personal data as the Customer&#39;s Processor in order to enable the use of
      the Software and Services as defined under the End User License Agreement according to
      documented instructions (in accordance with the product functionality) of the Customer and/or
      its users. This essentially covers the processing of the transmitted data values as well as
      the organization of the contents of the user account. This would typically amount to:
      <br />
      <br />
      - Processing of the data that the user enters in their user account and making it accessible
      to other users for whom they have approved for access, _e.g.,_ name, contacts, email address,
      profile picture as well as data pertaining to devices on the vessel, including location
      services (where enabled). - Processing of contacts stored in the user&#39;s account, _e.g_.,
      vessel user directory - Processing and transmission of data in the context of monitoring and
      control _e.g._, using LINK (directly or indirectly) to control devices onboard the vessel, or
      to receive alerts that they are subscribed too. - Transmission of message data entered by the
      respective user to other users within the message thread (this can include non-text objects
      such as images or other attachments). - Processing of data in the context of company profile
      management, such as intercompany relationships, administration of the company profile,
      customer profiles, user access management, audit reports _etc_.
      <br />
      <br />
      1. Type of personal data
      <br />
      <br />
      As part of the intended functionality of LINK, the following types of personal data are
      processed by LINK as a Processor:
      <br />
      <br />
      - Content data that is exchanged between a LINKbridge and the LINKCloud or LINK Smart Phone
      App clients _e.g._, cctv cameras, documentation uploads/downloads, messaging (including
      non-text elements), remote control commands, user permissions and administrations, vessel
      device data point values (historic and current), vessel positioning, vessel subscription
      information. - User account information, _e.g.,_ email, display name, IP address, profile
      picture, vessel profile picture, language preference, location, password, billing information,
      subscription information, organisational membership information. - Personal data in connection
      with the user account management and administration, _e.g.,_ user profile storing and sharing,
      account details, vessel directory, contact information, chat history, documentation storage,
      notes. - Personal data in connection with the company profile administration and management
      data, _e.g.,_ company profile, company policies, associations with user accounts, user access
      management, connection reports. - Mobile data usage statics including spend. - Configuration
      data for the local client (i.e. LINKbridge) - Notifications and alerts created by the users. -
      Personal data processed within the electronic mailing services (_e.g.,_ notifying, updating,
      and reporting parameters as defined by the Customer). - Personal data processed in connection
      with password reset (_e.g.,_ hosting account reset and mailing service, email with reset link,
      assignment of the new password to the account) as well as trusted device management (_e.g.,_
      email notifications to prevent misuse of a device for login).
      <br />
      <br />
      5. Categories of data subjects
      <br />
      <br />
      The following categories of data subjects are affected by the data processing:
      <br />
      <br />
      1. The Customer (typically the vessel owner) (to the extent that the Customer&#39;s personal
      data is processed in accordance with section 2) and, if applicable, the Customer&#39;s users
      (invited users of the vessel). 2. The vessel owner from whose vessel data is transmitted (if
      that person is not the Customer themselves). 3. Companies who have an interest in the Customer
      (or maybe a customer themselves) who are managing vessels at a higher level (e.g. a
      manufacturer or dealer)
      <br />
      <br />
      6. Additional Notes
      <br />
      <br />
      Data processing is described in and is subject to the term End User License Agreement. The
      duration of any data processing corresponds the duration of the End User License Agreement, or
      express provisions contained therein.
      <br />
      <br />
      9th March 2021
      <br />
      <br />
    </div>
  );
};

export default DPA;
