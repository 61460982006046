import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getClientsForCompany(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/myOrganisation/clients',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postInviteClient(email: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/clients/add?email=${email}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function clientPostNote(
  messageText: string,
  clientId: string | number
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/clients/${clientId}/notes`,
    method: 'POST',
    data: {
      messageText,
    },
  };
  return requestMiddleware(config);
}

export async function clientRemoveNote(
  noteId: string | number,
  clientId: string | number
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/clients/${clientId}/notes/${noteId}`,
    method: 'DELETE',
  };
  return requestMiddleware(config);
}

export async function postRemoveClient(clientId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/clients/${clientId}`,
    method: 'DELETE',
  };
  return requestMiddleware(config);
}

export async function postResendInvitation(id: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/clients/resendInvite?clientId=${id}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}
