import {
  getAllBuses,
  getAllDeviceManufacturers,
  getAllManufacturers,
  getModelsForManufacturer,
} from 'api/manufacturers';
import { AppThunk, MyThunkDispatch } from 'store';
import { storeLoader } from 'store/helpers';

import { manufacturerActions } from '.';

const {
  fetchAllManufacturersSuccess,
  fetchAllBusesSuccess,
  fetchModelsForManufacturerSuccess,
  fetchAllDeviceManufacturersSuccess,
  fetchManufacturersFailure,
  fetchManufacturersStart,
} = manufacturerActions;

const manufacturersLoader = (dispatch: MyThunkDispatch, reducer: () => Promise<void>): void => {
  storeLoader(dispatch, reducer, fetchManufacturersStart, fetchManufacturersFailure);
};

export const fetchAllManufacturers = (): AppThunk => async dispatch => {
  manufacturersLoader(dispatch, async () => {
    const response = await getAllManufacturers();
    dispatch(fetchAllManufacturersSuccess(response.data));
  });
};

export const fetchAllBuses = (): AppThunk => async dispatch => {
  manufacturersLoader(dispatch, async () => {
    const response = await getAllBuses();
    dispatch(fetchAllBusesSuccess(response.data));
  });
};

export const fetchAllDeviceManufacturers = (): AppThunk => async dispatch => {
  manufacturersLoader(dispatch, async () => {
    const response = await getAllDeviceManufacturers();
    dispatch(fetchAllDeviceManufacturersSuccess(response.data));
  });
};

export const fetchModelsForManufacturer =
  (manufacturerId: string): AppThunk =>
  async dispatch => {
    manufacturersLoader(dispatch, async () => {
      const response = await getModelsForManufacturer(manufacturerId);
      dispatch(
        fetchModelsForManufacturerSuccess({
          id: manufacturerId,
          modelsList: response.data,
        })
      );
    });
  };
