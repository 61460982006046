import React from 'react';

type Props = {
  color?: string;
  type?: string;
  iconStyle?: React.CSSProperties;
};

const ToastIcons: React.FC<Props> = props => {
  const { color, type, iconStyle } = props;

  switch (type) {
    case 'ERROR':
      return (
        <div style={{ ...iconStyle }}>
          <svg viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              fill={color}
              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"></path>
          </svg>
        </div>
      );
    case 'SUCCESS':
      return (
        <div style={{ ...iconStyle }}>
          <svg viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              fill={color}
              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"></path>
          </svg>
        </div>
      );
    default:
      return (
        <div style={{ ...iconStyle }}>
          <svg viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              fill="red"
              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"></path>
          </svg>
        </div>
      );
  }
};

export default ToastIcons;
