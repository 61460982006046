import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { brandBlue } from '../../styles/colors';

export interface Tab {
  display: string;
  code: string;
}

type Props = {
  availableTabs: Tab[];
  currentTab: string;
  path: string;
  small?: boolean;
};

function CardTabs(props: Props): ReactElement {
  const { availableTabs, currentTab, path, small } = props;
  const navigate = useNavigate();

  return (
    <TabButtonsWrapper small={small}>
      {availableTabs.map((tab, idx) => {
        const isActive = currentTab.toUpperCase() === tab.code;
        return (
          <TabButton
            key={idx}
            onClick={() => navigate(`${path}${tab.code.toLowerCase()}`)}
            active={isActive}>
            {tab.display}
          </TabButton>
        );
      })}
    </TabButtonsWrapper>
  );
}

export default CardTabs;

interface TabButtonsWrapperProps {
  small?: boolean;
}

const TabButtonsWrapper = styled.div<TabButtonsWrapperProps>`
  flex: 1;
  margin-right: ${({ small }) => (small ? '10px' : '300px')};
`;

const TabButton = styled.button<{ active?: boolean }>`
  background: transparent;
  cursor: pointer;
  border: none;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
  padding: 11px 10px 10px 10px;
  font-size: 16px;
  outline: none;
  width: fit-content;
  color: #5e5f64;
  transition: color 0.2s ease, border-bottom-color 0.2s ease;

  &:first-child {
    padding-left: 0;
  }

  ${({ active }) =>
    active &&
    `
      color: ${brandBlue};
      border-bottom-color: ${brandBlue};
    `}
`;
