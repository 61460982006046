import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ThunkAPIConfig {}

class DelayLoading {
  timeout: NodeJS.Timeout | null = null;
  forceLoaderTimeout: NodeJS.Timeout | null = null;

  clearLoadingTimeout = (): void => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  clearForceLoadingTimeout = (): void => {
    if (this.forceLoaderTimeout) {
      clearTimeout(this.forceLoaderTimeout);
    }
  };

  delayLoadingAsyncThunk = <Returned, ThunkArg = void | string>(
    type: string,
    thunk: AsyncThunkPayloadCreator<Returned, ThunkArg>
  ): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> => {
    return createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(type, async (arg, thunkAPI) => {
      try {
        this.timeout = setTimeout(() => {
          if (!type.includes('_LOADING_START')) {
            thunkAPI.dispatch({ type: `${type}_LOADING_START` });
            //If loading takes more than 1 sec show loading but force it to stay for at least 1 sec to remove flashing
            this.forceLoaderTimeout = setTimeout(() => {
              if (!type.includes('_FORCE_LOADING')) {
                thunkAPI.dispatch({ type: `${type}_FORCE_LOADING` });
              }
            }, 1000);
          }
        }, 1000);
        return await thunk(arg, thunkAPI);
      } catch (err) {
        return thunkAPI.rejectWithValue(null);
      }
    });
  };
}

export default DelayLoading;
