import { useCallback, useEffect } from 'react';

import { Toast, ToastPosition } from '../types';
import { toast } from '../utilities';
import { ActionType, dispatch, useStore } from './store';

const updateHeight = (toastId: string, height: number): void => {
  dispatch({
    type: ActionType.UPDATE_TOAST,
    toast: { id: toastId, height },
  });
};

type ToastReturn = {
  toasts: Toast[];
  handlers: {
    updateHeight: typeof updateHeight;
    removeToast: (id?: string) => void;
    calculateOffset: (
      toast: Toast,
      opts?: {
        reverseOrder?: boolean;
        gutter?: number;
        defaultPosition?: ToastPosition;
      }
    ) => number;
  };
};

export const useToast = (): ToastReturn => {
  const { toasts } = useStore();

  useEffect(() => {
    const now = Date.now();
    const timeouts = toasts.map(t => {
      if (t.duration === Infinity) {
        return;
      }

      const durationLeft = (t.duration || 0) - (now - t.createdAt);

      if (durationLeft < 0) {
        if (t.visible) {
          toast.dismiss(t.id);
        }
        return;
      }
      return setTimeout(() => toast.dismiss(t.id), durationLeft);
    });

    return () => {
      timeouts.forEach(timeout => timeout && clearTimeout(timeout));
    };
  }, [toasts]);

  const removeToast = useCallback((id?: string) => {
    dispatch({ type: ActionType.DISMISS_TOAST, toastId: id });
  }, []);

  const calculateOffset = useCallback(
    (
      toast: Toast,
      opts?: {
        reverseOrder?: boolean;
        gutter?: number;
        defaultPosition?: ToastPosition;
      }
    ) => {
      const { reverseOrder = false, gutter = 8, defaultPosition } = opts || {};

      const relevantToasts = toasts.filter(
        t => (t.position || defaultPosition) === (toast.position || defaultPosition) && t.height
      );
      const toastIndex = relevantToasts.findIndex(t => t.id === toast.id);
      const toastsBefore = relevantToasts.filter(
        (toast, i) => i < toastIndex && toast.visible
      ).length;

      return relevantToasts
        .filter(t => t.visible)
        .slice(...(reverseOrder ? [toastsBefore + 1] : [0, toastsBefore]))
        .reduce((acc, t) => acc + (t.height || 0) + gutter, 0);
    },
    [toasts]
  );

  return {
    toasts,
    handlers: {
      updateHeight,
      removeToast,
      calculateOffset,
    },
  };
};
