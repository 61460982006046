import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { brandBlue, brandDarkGrey } from 'styles/colors';

export function Splash(props: { loadingFadeOut?: boolean }): ReactElement {
  const [dotColors, setDotColors] = useState<string[]>(['#fff', brandDarkGrey, brandDarkGrey]);

  useEffect(() => {
    setInterval(() => {
      setDotColors(prevColors => {
        // Rotate colors by shifting the array
        const newColors: string[] = [...prevColors];
        newColors.unshift(newColors.pop() || '');
        return newColors;
      });
    }, 500);
  }, []);

  return (
    <StatusScreen style={{ opacity: props.loadingFadeOut ? 0 : 1 }}>
      LINK
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {dotColors.map((color, index) => (
          <div
            key={index}
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              margin: '0 5px',
              backgroundColor: color,
            }}
          />
        ))}
      </div>
    </StatusScreen>
  );
}

const StatusScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${brandBlue};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 100px;
  text-align: center;
  transition: 0.5s;
`;
