import { combineReducers } from '@reduxjs/toolkit';

import accountReducer from './account';
import adminReducer from './admin';
import adminSecurityReducer from './adminSecurity';
import clientsReducer from './clients';
import contactsReducer from './contacts';
import contractorsReducer from './contractors';
import dealersReducer from './dealers';
import localReducer from './local';
import manufacturersReducer from './manufacturers';
import messageThreadsReducer from './messages';
import myOrganisationReducer from './myOrganisation';
import profileReducer from './profile';
import selectedClientReducer from './selectedClient';
import selectedDeviceReducer from './selectedDevice';
import selectedVesselReducer from './selectedVessel';
import simpleDevicesReducer from './simpleDevice';
import theme from './theme';
import unitPreferenceReducer from './unitPreference';
import usersReducer from './users';
import vesselsReducer from './vessels';
import userGroupsReducer from './vesselUserGroup';

const rootReducer = combineReducers({
  admin: adminReducer,
  adminSecurity: adminSecurityReducer,
  account: accountReducer,
  vessels: vesselsReducer,
  dealers: dealersReducer,
  profile: profileReducer,
  manufacturers: manufacturersReducer,
  selectedVessel: selectedVesselReducer,
  selectedDevice: selectedDeviceReducer,
  selectedClient: selectedClientReducer,
  myOrganisation: myOrganisationReducer,
  messageThreads: messageThreadsReducer,
  contractors: contractorsReducer,
  contacts: contactsReducer,
  clients: clientsReducer,
  userGroups: userGroupsReducer,
  users: usersReducer,
  simpleDevices: simpleDevicesReducer,
  local: localReducer,
  theme: theme,
  unit: unitPreferenceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
