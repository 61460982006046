import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllCompanies } from 'api/admin';
import { MyResponse } from 'api/request';
import { getAllCountries } from 'api/utils';
import { AppThunk, ThunkResponse } from 'store';
import { fetchFailed, fetchStart } from 'store/helpers';

import { fetchAllDealers } from '../dealers';
import { Country, Dealer, ModuleState } from '../types';
import { fetchAllModels } from '../vessels';

interface AdminState extends ModuleState {
  companies: Dealer[];
  countries: Country[];
}

const adminInitialState: AdminState = {
  companies: [],
  countries: [],
  isLoading: false,
  error: null,
};

const admin = createSlice({
  name: 'admin',
  initialState: adminInitialState,
  reducers: {
    fetchAdminDataStart: fetchStart,
    fetchAdminDataFailure: fetchFailed,
    fetchAdminDataSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    fetchAllCompaniesSuccess(state, { payload }: PayloadAction<Dealer[]>) {
      state.companies = payload;
      state.error = null;
    },
    fetchAllCountriesSuccess(state, { payload }: PayloadAction<Country[]>) {
      state.countries = payload;
      state.error = null;
    },
  },
});

export const {
  fetchAdminDataStart,
  fetchAdminDataSuccess,
  fetchAllCompaniesSuccess,
  fetchAllCountriesSuccess,
  fetchAdminDataFailure,
} = admin.actions;

export default admin.reducer;

export const fetchAllCompanies = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminDataStart());
    const response = await getAllCompanies();
    dispatch(fetchAllCompaniesSuccess(response.data));
  } catch (err) {
    dispatch(fetchAdminDataFailure(err.toString()));
    return err;
  }
};
export const fetchAllCountries = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminDataStart());
    const response = await getAllCountries();
    dispatch(fetchAllCountriesSuccess(response.data));
    dispatch(fetchAdminDataSuccess());
  } catch (err) {
    dispatch(fetchAdminDataFailure(err.toString()));
    return err;
  }
};

export const fetchAdminData = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchAdminDataStart());
    dispatch(fetchAllDealers());
    dispatch(fetchAllModels());

    await Promise.allSettled([dispatch(fetchAllCompanies()), dispatch(fetchAllCountries())]);

    dispatch(fetchAdminDataSuccess());
  } catch (err) {
    dispatch(fetchAdminDataFailure(err.toString()));
    return err;
  }
};
