/* eslint-disable indent */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classNames from 'classnames';
import ContentLoader from 'components/ContentLoader';
import React, { ReactElement, ReactNode } from 'react';

import styles from './Button.module.scss';

type Props = React.ComponentProps<'button'> & {
  children?: ReactNode;
  variant?: 'info' | 'inactive' | 'error' | 'inverted' | 'custom' | 'rgb';
  size?: 'normal' | 'small' | 'inline' | 'notification';
  loading?: boolean;
  disabled?: boolean;
  className?: any;
};

const Button = ({
  children,
  variant,
  size,
  loading,
  disabled,
  className,
  ...props
}: Props): ReactElement => {
  const defineButtonStyle = (): string => {
    const buttonStyle = [styles.buttonLink];
    switch (size) {
      case 'small':
        buttonStyle.push(styles.buttonLinkSmall);
        break;
      case 'inline':
        buttonStyle.push(styles.buttonLinkInline);
        break;
      case 'notification':
        buttonStyle.push(styles.buttonLinkNotification);
        break;
    }
    switch (variant) {
      case 'info':
        buttonStyle.push(styles.buttonInfo);
        break;
      case 'inactive':
        buttonStyle.push(styles.buttonInactive);
        break;
      case 'error':
        buttonStyle.push(styles.buttonError);
        break;
      case 'custom':
        buttonStyle.push(styles.buttonCustom);
        break;
      case 'inverted':
        buttonStyle.push(styles.buttonInverted);
        break;
      case 'rgb':
        buttonStyle.push(styles.rgb);
        break;
      default:
        buttonStyle.push(styles.buttonInfo);
        break;
    }
    return classNames(buttonStyle);
  };

  return (
    <button
      className={classNames([defineButtonStyle(), className])}
      disabled={loading || disabled}
      {...props}>
      {loading ? <ContentLoader button={true} /> : children}
    </button>
  );
};

export default Button;
