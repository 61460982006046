import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetUnreachable } from 'store/local';
import { RootState } from 'store/rootReducer';
import { CriticalInfo } from 'styles/containers';

import { saveError } from '../../store/local/actions';
import Modal from '../Modal';

const UnreachableComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const unreachableVisible = useSelector((state: RootState) => state.local.unreachableVisible);
  const unreachableCount = useSelector((state: RootState) => state.local.unreachableCount);
  const [hasDisconnected, setHasDisconnected] = useState(false);

  useEffect(() => {
    if (unreachableVisible) {
      setHasDisconnected(true);
    }
    if (hasDisconnected && !unreachableVisible) {
      try {
        window.location.reload();
      } catch (error) {
        dispatch(saveError(error));
      }
      setHasDisconnected(false);
    }
  }, [unreachableVisible, unreachableCount, hasDisconnected]);

  return unreachableVisible ? (
    <Modal
      handleClose={() => {
        dispatch(resetUnreachable());
      }}
      title={'Connection Issue'}>
      <CriticalInfo style={{ margin: 0 }}>
        There is an issue with the server connection. Please check if you have a reliable internet
        connection. If your internet connection is stable, we apologize for the inconvenience. The
        server should be back online shortly.
      </CriticalInfo>
    </Modal>
  ) : null;
};

export default UnreachableComponent;
