import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchFailed, fetchStart } from '../helpers';
import { getCorporatePermissionContainers, getMyOrganisation } from './api';
import { MyOrganisationState, MyOrganisationTabs } from './types';

const myOrganisationInitialState: MyOrganisationState = {
  myOrganisation: null,
  currentRequestId: undefined,
  staffPermissions: [],
  selectedTab: 'OVERVIEW',
  isLoading: false,
  error: null,
};

const myOrganisation = createSlice({
  name: 'myOrganisation',
  initialState: myOrganisationInitialState,
  reducers: {
    fetchMyOrganisationStart: fetchStart,
    fetchMyOrganisationFailure: fetchFailed,
    setMyOrganisationTab(state, { payload }: PayloadAction<MyOrganisationTabs>) {
      state.selectedTab = payload;
    },
    updateCorporatePausedStatus(state, { payload }: PayloadAction<string>) {
      const updatedIndex = state.staffPermissions.findIndex(p => p.id === payload);
      if (updatedIndex) {
        const updatedStaff = [...state.staffPermissions];
        updatedStaff[updatedIndex].paused = !updatedStaff[updatedIndex].paused;
        state.staffPermissions = updatedStaff;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getMyOrganisation.pending, (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isLoading) {
        state.isLoading = true;
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(getMyOrganisation.fulfilled, (state, { payload }: PayloadAction<any>) => {
      state.myOrganisation = payload.data;
      state.isLoading = false;
    });
    builder.addCase(getMyOrganisation.rejected, (state, action) => {
      const { requestId } = action.meta;
      if (state.isLoading && state.currentRequestId === requestId) {
        state.error = action.error;
        state.isLoading = false;
      }
    });
    builder.addCase(getCorporatePermissionContainers.pending, (state, { meta }) => {
      const { requestId } = meta;
      if (!state.isLoading) {
        state.isLoading = true;
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(
      getCorporatePermissionContainers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.staffPermissions = payload.data;
        state.isLoading = false;
      }
    );
    builder.addCase(getCorporatePermissionContainers.rejected, (state, action) => {
      const { requestId } = action.meta;
      if (state.isLoading && state.currentRequestId === requestId) {
        state.error = action.error;
        state.isLoading = false;
      }
    });
  },
});

export const { setMyOrganisationTab, updateCorporatePausedStatus } = myOrganisation.actions;

export default myOrganisation.reducer;
