import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CardTabs, { Tab } from '../../components/TabsNav';
import { BannerContext } from '../../context/BannerContext';
import { HeadingContext } from '../../context/HeadingContext';
import { standardShadow, standardWhite } from '../../styles/colors';
import DPA from './DPA';
import EULA from './EULA';
import PrivacyPolicy from './PrivacyPolicy';

const availableTabs: Tab[] = [
  { display: 'End User License Agreement', code: 'EULA' },
  { display: 'Privacy Policy', code: 'PRIVACY-POLICY' },
  { display: 'Data Processing Agreement', code: 'DPA' },
];

type Props = {
  appMode?: boolean;
};

const TermsView = (props: Props): ReactElement => {
  const { tabCode } = useParams<{ tabCode: string }>();
  const location = useLocation();
  const { setMainHeading } = useContext(HeadingContext);

  const { setType, setMessage, reset } = useContext(BannerContext);

  useEffect(() => {
    setType('GENERAL');
    setMessage(
      'By using this site or any LINK Services you are agreeing to the terms and conditions outlined below.'
    );
    return () => {
      reset();
    };
  }, [location]);

  useEffect(() => {
    setMainHeading([{ text: 'Terms of Use' }]);
    return () => {
      setMainHeading([]);
    };
  }, []);

  const [currentTab, setCurrentTab] = useState<string>(
    tabCode ? tabCode.toUpperCase() : availableTabs[0].code
  );
  useEffect(() => {
    if (tabCode) {
      setCurrentTab(tabCode.toUpperCase());
    }
  }, [tabCode]);

  const viewSwitcher = (
    <CardTabs
      availableTabs={availableTabs}
      currentTab={currentTab}
      path={props.appMode ? '/terms/' : '/dashboard/terms-of-use/'}
    />
  );

  return (
    <ContentWrap style={props.appMode ? { margin: 'unset' } : {}}>
      <TabWrap>{viewSwitcher}</TabWrap>
      {currentTab === availableTabs[0].code && <EULA />}
      {currentTab === availableTabs[1].code && <PrivacyPolicy />}
      {currentTab === availableTabs[2].code && <DPA />}
    </ContentWrap>
  );
};
export default TermsView;

const ContentWrap = styled.div`
  box-shadow: ${standardShadow};
  border-radius: 4px;
  margin: 0 20px 20px 20px;
  background-color: ${standardWhite};
  padding: 20px;
`;

const TabWrap = styled.div`
  margin-bottom: 20px;
`;
