import { ErrorDetails } from 'types/errorHandler';

import { version } from '../../../package.json';
import { getEnvironment } from '../environment';
import { timestamp, timezone } from '../timestamp';
import { errorMessage } from './utils/errorMessage';
import { userAgentBrowser } from './utils/userAgentBrowser';
import { userAgentOs } from './utils/userAgentOs';

const generateErrorObject = (
  caughtError: any,
  requestData?: any,
  frontendErrorMessage?: string
): ErrorDetails | null => {
  if (caughtError && caughtError.response) {
    if (caughtError.response.status === 502) return null;
    else {
      const res = caughtError.response;
      const status: number = res.status;
      const teapotMessage: string | null =
        status === 418 || status === 401 ? res.data.message : null;

      return {
        status,
        displayedMsg: frontendErrorMessage ?? teapotMessage ?? errorMessage(status),
        timestamp: timestamp(new Date(), 'ISO', true),
        timezone: timezone(new Date()),
        platform: status ? 'CLOUD_API' : 'CLOUD_UI',
        uiVersion: version,
        browser: userAgentBrowser(navigator.userAgent),
        os: userAgentOs(navigator.userAgent),
        endpoint: res.config.url,
        requestData,
        comment: '',
        userId: '',
        userName: '',
        vesselId: '',
        vesselName: '',
        environment: getEnvironment(),
      };
    }
  } else return null;
};

export default generateErrorObject;
