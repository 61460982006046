export function userAgentBrowser(uA: string): string {
  let browserName = 'Unknown browser';
  let browserVersion = '';
  let bvOffset: number;
  let index: number;

  // MS Edge
  if ((bvOffset = uA.indexOf('Edg')) !== -1) {
    browserName = 'MS Edge';
    browserVersion = uA.substring(bvOffset + 4);
  }
  // Opera
  else if ((bvOffset = uA.indexOf('OPR')) !== -1) {
    browserName = 'Opera';
    browserVersion = uA.substring(bvOffset + 4);
  }
  // Samsung (mobile)
  else if ((bvOffset = uA.indexOf('SamsungBrowser')) !== -1) {
    browserName = 'Samsung';
    browserVersion = uA.substring(bvOffset + 15);
  }
  // Chrome
  else if ((bvOffset = uA.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    browserVersion = uA.substring(bvOffset + 7);
  }
  // Safari
  else if ((bvOffset = uA.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    browserVersion = uA.substring(bvOffset + 7);
    if ((bvOffset = uA.indexOf('Version')) !== -1) browserVersion = uA.substring(bvOffset + 8);
  }
  // Firefox
  else if ((bvOffset = uA.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    browserVersion = uA.substring(bvOffset + 8);
  }

  if ((index = browserVersion.indexOf(';')) !== -1)
    browserVersion = browserVersion.substring(0, index);
  if ((index = browserVersion.indexOf(' ')) !== -1)
    browserVersion = browserVersion.substring(0, index);

  return browserName + ' ' + browserVersion;
}
