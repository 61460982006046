import React, { createContext, useState } from 'react';

export interface BreadcrumbPart {
  text: string;
  link?: string;
}

interface HeadingContextInterface {
  sidebarHeading: string;
  mainHeading: BreadcrumbPart[];
  imageUrl: string | null;
  setSidebarHeading: (heading: string) => void;
  setMainHeading: (heading: BreadcrumbPart[]) => void;
  setImageUrl: (url: string | null) => void;
}

const defaultValues: HeadingContextInterface = {
  sidebarHeading: '',
  mainHeading: [],
  imageUrl: null,
  setSidebarHeading: () => {
    return;
  },
  setMainHeading: () => {
    return;
  },
  setImageUrl: () => {
    return;
  },
};

const HeadingContext = createContext<HeadingContextInterface>(defaultValues);

const HeadingContextProvider: React.FC = ({ children }) => {
  const [sidebarHeading, setSidebarHeading] = useState<string>(defaultValues.sidebarHeading);
  const [mainHeading, setMainHeading] = useState<BreadcrumbPart[]>(defaultValues.mainHeading);
  const [imageUrl, setImageUrl] = useState<string | null>(defaultValues.imageUrl);

  return (
    <HeadingContext.Provider
      value={{
        sidebarHeading,
        mainHeading,
        imageUrl,
        setSidebarHeading,
        setMainHeading,
        setImageUrl,
      }}>
      {children}
    </HeadingContext.Provider>
  );
};

export { HeadingContext, HeadingContextProvider };
