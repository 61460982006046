import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getContacts } from 'api/addressBook';
import { AppThunk } from 'store';

import { fetchFailed, fetchStart } from '../helpers';
import { ModuleState, UserBasicData } from '../types';
import { CorporateContact } from './types';

interface ContactsState extends ModuleState {
  contactsList: {
    clients: UserBasicData[];
    contractors: CorporateContact[];
    dealers: CorporateContact[];
    manufacturers: CorporateContact[];
    staff: UserBasicData[];
  };
}

const messageThreadsInitialState: ContactsState = {
  contactsList: {
    clients: [],
    contractors: [],
    dealers: [],
    manufacturers: [],
    staff: [],
  },
  isLoading: false,
  error: null,
};

const contacts = createSlice({
  name: 'contacts',
  initialState: messageThreadsInitialState,
  reducers: {
    fetchAllContactsStart: fetchStart,
    fetchAllContactsSuccess(state, { payload }: PayloadAction<any>) {
      state.contactsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchAllContactsFailure: fetchFailed,
  },
});

export const { fetchAllContactsStart, fetchAllContactsSuccess, fetchAllContactsFailure } =
  contacts.actions;

export default contacts.reducer;

export const fetchAllContacts = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchAllContactsStart());
    const response = await getContacts();
    dispatch(fetchAllContactsSuccess(response.data));
  } catch (err) {
    dispatch(fetchAllContactsFailure(err.toString()));
    return err;
  }
};
