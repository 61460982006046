import { CSSProperties } from 'react';

export type MessageObj = { loading: string; success: string; error: string };
export type Message = string;

export type ToastType = 'success' | 'error' | 'loading' | 'blank' | 'custom';
export type ToastPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

export interface Toast {
  type: ToastType;
  id: string;
  message: Message;
  duration?: number;
  position?: ToastPosition;
  style?: CSSProperties;
  createdAt: number;
  visible: boolean;
  height?: number;
}

export type ToastOptions = Partial<Pick<Toast, 'id' | 'duration' | 'style' | 'position'>>;

export type DefaultToastOptions = ToastOptions & {
  [key in ToastType]?: ToastOptions;
};

export const defaultTimeouts: {
  [key in ToastType]: number;
} = {
  blank: 4000,
  error: 4000,
  success: 2000,
  loading: Infinity,
  custom: 4000,
};
