import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getClientDetails, getClientNotes, getClientVessels } from 'api/selectedClient';
import { AppThunk, ThunkResponse } from 'store';
import { BasicVessel } from 'store/vessels/types';

import { fetchFailed, fetchStart, loadingSuccess } from '../helpers';
import { ModuleState, Note } from '../types';

export interface ClientDetails {
  id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  isAdmin: boolean;
  telephoneNumber: string;
  mobileNumber: string;
  smsNotify: boolean;
  whatsAppNotify: boolean;
  state?: string;
  vesselCount: number;
  authVesselCount: number;
  activeAuthVesselCount: number;
}

interface ClientsState extends ModuleState {
  client: ClientDetails | null;
  vessels: BasicVessel[];
  notes: Note[];
}

const clientsInitialState: ClientsState = {
  client: null,
  vessels: [],
  notes: [],
  isLoading: false,
  error: null,
};

const fetchClientDetails = createAsyncThunk(
  'selectedClient/fetchClientDetails',
  async (clientId: string) => {
    const response = await getClientDetails(clientId);
    return response.data;
  }
);

const fetchClientVessels = createAsyncThunk(
  'selectedClient/fetchClientVessels',
  async (clientId: string) => {
    const response = await getClientVessels(clientId);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

const fetchClientNotes = createAsyncThunk(
  'selectedClient/fetchClientNotes',
  async (clientId: string) => {
    const response = await getClientNotes(clientId);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

const selectedClient = createSlice({
  name: 'selectedClient',
  initialState: clientsInitialState,
  reducers: {
    fetchSelectedClientStart: fetchStart,
    fetchSelectedClientSuccess: loadingSuccess,
    fetchSelectedClientFailure: fetchFailed,
  },
  extraReducers: builder => {
    builder.addCase(
      fetchClientDetails.fulfilled,
      (state, { payload }: PayloadAction<ClientDetails>) => {
        state.client = payload;
      }
    );
    builder.addCase(
      fetchClientVessels.fulfilled,
      (state, { payload }: PayloadAction<BasicVessel[]>) => {
        state.vessels = payload;
      }
    );
    builder.addCase(fetchClientNotes.fulfilled, (state, { payload }: PayloadAction<Note[]>) => {
      state.notes = payload;
    });
  },
});

export const { fetchSelectedClientStart, fetchSelectedClientSuccess, fetchSelectedClientFailure } =
  selectedClient.actions;

export const loadClientDetails =
  (clientId: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchSelectedClientStart());

      await Promise.all([
        dispatch(fetchClientDetails(clientId)),
        dispatch(fetchClientVessels(clientId)),
        dispatch(fetchClientNotes(clientId)),
      ]);

      dispatch(fetchSelectedClientSuccess());
    } catch (err) {
      dispatch(fetchSelectedClientFailure(err.toString()));
      return err;
    }
  };

export const loadClientNotes =
  (clientId: string): ThunkResponse<Promise<any>> =>
  async dispatch => {
    try {
      await dispatch(fetchClientNotes(clientId));
    } catch (err) {
      return err;
    }
  };

export default selectedClient.reducer;
