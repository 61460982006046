import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { borderRadius } from '../styles';

type Props = {
  bgColor: string;
  completed: number;
};

const ProgressBar: React.FC<Props> = props => {
  const { bgColor, completed } = props;
  const theme = {
    backgroundColor: bgColor,
    width: completed,
  };
  return (
    <Container>
      <ThemeProvider theme={theme}>
        <Filler />
      </ThemeProvider>
    </Container>
  );
};

export default ProgressBar;

const Container = styled.div`
  height: 5px;
  width: 100%;
  background-color: #e0e0de;
  border-bottom-left-radius: ${borderRadius - 2}px;
  border-bottom-right-radius: ${borderRadius}px;
`;
const Filler = styled.div`
  height: 100%;
  width: ${props => props.theme.width}%;
  background-color: ${props => props.theme.backgroundColor};
  border-bottom-left-radius: inherit;
  text-align: right;
`;
