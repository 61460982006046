import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuditTrailPost,
  getAdminContractors,
  getAdminDealers,
  getAdminManufacturers,
  getAllDocumentCategories,
  getAllNavigationComponents,
  getAllReportingClients,
  getAllSubscriptions,
  getAllUserLanguages,
  getAllUsers,
  getAuditTrail,
  getAuditTrailPaginated,
  getSubscriptionVouchers,
} from 'api/admin';
import { MyResponse } from 'api/request';
import { ThunkResponse } from 'store';
import { fetchFailed, fetchStart } from 'store/helpers';

import { getAllUserTypes } from '../../api/vessels';
import { ModuleState } from '../types';
import { OrganisationState, UserType } from '../vesselUserGroup';
import {
  AdminReportingClient,
  AuditTrail,
  DocumentCategory,
  NavigationComponent,
  UserAdminData,
  UserLanguage,
} from './types';

//TODO: Dave - tidy and typify this file

interface AdminState extends ModuleState {
  users: UserAdminData[];
  navigationComponents: NavigationComponent[];
  auditTrail: AuditTrail[];
  dealers: any[];
  manufacturers: OrganisationState[];
  contractors: OrganisationState[];
  products: any[];
  userTypes: UserType[];
  userLanguages: UserLanguage[];
  reportingClients: AdminReportingClient[];
  documentCategories: DocumentCategory[];
  subscriptions: any[];
  subscriptionVouchers: any[];
  size: number;
}

const adminInitialState: AdminState = {
  users: [],
  navigationComponents: [],
  auditTrail: [],
  dealers: [],
  manufacturers: [],
  contractors: [],
  products: [],
  userTypes: [],
  userLanguages: [],
  reportingClients: [],
  documentCategories: [],
  subscriptions: [],
  subscriptionVouchers: [],
  isLoading: false,
  error: null,
  size: 0,
};

const admin = createSlice({
  name: 'adminSecurity',
  initialState: adminInitialState,
  reducers: {
    fetchAdminSecurityDataStart: fetchStart,
    fetchAdminSecurityDataFailure: fetchFailed,
    fetchAdminSecurityDataSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    fetchAllUsersSuccess(state, { payload }: PayloadAction<UserAdminData[]>) {
      state.users = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAuditTrailSuccess(state, { payload }: PayloadAction<any[]>) {
      state.auditTrail = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllNavigationComponentsSuccess(state, { payload }: PayloadAction<any[]>) {
      state.navigationComponents = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllUserTypesSuccess(state, { payload }: PayloadAction<any[]>) {
      state.userTypes = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllDealersSuccess(state, { payload }: PayloadAction<any[]>) {
      state.dealers = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllManufacturersSuccess(state, { payload }: PayloadAction<any[]>) {
      state.manufacturers = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllContractorsSuccess(state, { payload }: PayloadAction<any[]>) {
      state.contractors = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllProductsSuccess(state, { payload }: PayloadAction<any[]>) {
      state.products = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllUserLanguagesSuccess(state, { payload }: PayloadAction<any[]>) {
      state.userLanguages = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllReportingClientsSuccess(state, { payload }: PayloadAction<AdminReportingClient[]>) {
      state.reportingClients = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllDocumentCategoriesSuccess(state, { payload }: PayloadAction<any[]>) {
      state.documentCategories = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllSubscriptionsSuccess(state, { payload }: PayloadAction<any[]>) {
      state.subscriptions = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAllSubscriptionVouchersSuccess(state, { payload }: PayloadAction<any[]>) {
      state.subscriptionVouchers = payload;
      state.error = null;
      state.isLoading = false;
    },
    fetchAuditSizeSuccess(state, { payload }: PayloadAction<number>) {
      state.size = payload;
      state.error = null;
      state.isLoading = false;
    },
  },
});

export const {
  fetchAdminSecurityDataStart,
  fetchAdminSecurityDataSuccess,
  fetchAllUsersSuccess,
  fetchAuditTrailSuccess,
  fetchAllNavigationComponentsSuccess,
  fetchAllUserTypesSuccess,
  fetchAllDealersSuccess,
  fetchAllContractorsSuccess,
  fetchAllManufacturersSuccess,
  fetchAllProductsSuccess,
  fetchAllUserLanguagesSuccess,
  fetchAllReportingClientsSuccess,
  fetchAllDocumentCategoriesSuccess,
  fetchAdminSecurityDataFailure,
  fetchAllSubscriptionsSuccess,
  fetchAllSubscriptionVouchersSuccess,
  fetchAuditSizeSuccess,
} = admin.actions;

export default admin.reducer;

export const fetchAllUsers = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminSecurityDataStart());
    const response = await getAllUsers();
    response.data.sort((a: UserAdminData, b: UserAdminData) => {
      const emailA = a.email.toLowerCase();
      const emailB = b.email.toLowerCase();
      if (emailA < emailB) {
        return -1;
      }
      if (emailA > emailB) {
        return 1;
      }
      return 0;
    });
    dispatch(fetchAllUsersSuccess(response.data));
  } catch (err) {
    dispatch(fetchAdminSecurityDataFailure(err.toString()));
    return err;
  }
};

export const fetchAuditTrail = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminSecurityDataStart());
    const response = await getAuditTrail();
    dispatch(fetchAuditTrailSuccess(response.data));
  } catch (err) {
    dispatch(fetchAdminSecurityDataFailure(err.toString()));
    return err;
  }
};

export const fetchAuditTrailPaginated =
  (data: AuditTrailPost): ThunkResponse<Promise<MyResponse>> =>
  async dispatch => {
    try {
      dispatch(fetchAdminSecurityDataStart());
      const response = await getAuditTrailPaginated(data);
      dispatch(fetchAuditSizeSuccess(response.data.totalElements));
      dispatch(fetchAuditTrailSuccess(response.data.content));
    } catch (err) {
      dispatch(fetchAdminSecurityDataFailure(err.toString()));
      return err;
    }
  };

export const fetchAllNavigationComponents =
  (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
    try {
      dispatch(fetchAdminSecurityDataStart());
      const response = await getAllNavigationComponents();
      dispatch(fetchAllNavigationComponentsSuccess(response.data));
    } catch (err) {
      dispatch(fetchAdminSecurityDataFailure(err.toString()));
      return err;
    }
  };
export const fetchAllUserTypes = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminSecurityDataStart());
    const response = await getAllUserTypes();
    dispatch(fetchAllUserTypesSuccess(response.data));
  } catch (err) {
    dispatch(fetchAdminSecurityDataFailure(err.toString()));
    return err;
  }
};

export const fetchAllDealers = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminSecurityDataStart());
    const response = await getAdminDealers();
    dispatch(fetchAllDealersSuccess(response.data));
  } catch (err) {
    dispatch(fetchAdminSecurityDataFailure(err.toString()));
    return err;
  }
};
export const fetchAllManufacturers = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminSecurityDataStart());
    const response = await getAdminManufacturers();
    dispatch(fetchAllManufacturersSuccess(response.data));
  } catch (err) {
    dispatch(fetchAdminSecurityDataFailure(err.toString()));
    return err;
  }
};
export const fetchAllContractors = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminSecurityDataStart());
    const response = await getAdminContractors();
    dispatch(fetchAllContractorsSuccess(response.data));
  } catch (err) {
    dispatch(fetchAdminSecurityDataFailure(err.toString()));
    return err;
  }
};

export const fetchAllUserLanguages = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminSecurityDataStart());
    const response = await getAllUserLanguages();
    dispatch(fetchAllUserLanguagesSuccess(response.data));
  } catch (err) {
    dispatch(fetchAdminSecurityDataFailure(err.toString()));
    return err;
  }
};

export const fetchAllReportingClients =
  (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
    try {
      dispatch(fetchAdminSecurityDataStart());
      const response = await getAllReportingClients();
      dispatch(fetchAllReportingClientsSuccess(response.data));
    } catch (err) {
      dispatch(fetchAdminSecurityDataFailure(err.toString()));
      return err;
    }
  };

export const fetchAllDocumentCategories =
  (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
    try {
      dispatch(fetchAdminSecurityDataStart());
      const response = await getAllDocumentCategories();
      dispatch(fetchAllDocumentCategoriesSuccess(response.data));
    } catch (err) {
      dispatch(fetchAdminSecurityDataFailure(err.toString()));
      return err;
    }
  };

export const fetchAllSubscriptions = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    dispatch(fetchAdminSecurityDataStart());
    const response = await getAllSubscriptions();
    dispatch(fetchAllSubscriptionsSuccess(response.data));
  } catch (err) {
    dispatch(fetchAdminSecurityDataFailure(err.toString()));
    return err;
  }
};

export const fetchAllSubscriptionVouchers =
  (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
    try {
      dispatch(fetchAdminSecurityDataStart());
      const response = await getSubscriptionVouchers();
      dispatch(fetchAllSubscriptionVouchersSuccess(response.data));
    } catch (err) {
      dispatch(fetchAdminSecurityDataFailure(err.toString()));
      return err;
    }
  };
