import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchFailed, fetchStart } from '../helpers';
import { ModuleState, UserBasicData } from '../types';

interface UsersState extends ModuleState {
  usersList: UserBasicData[];
}

const usersInitialState: UsersState = {
  usersList: [],
  isLoading: false,
  error: null,
};

const users = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    fetchAllUsersStart: fetchStart,
    fetchAllUsersSuccess(state, { payload }: PayloadAction<UserBasicData[]>) {
      state.usersList = payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchAllUsersFailure: fetchFailed,
  },
});

export const { fetchAllUsersStart, fetchAllUsersSuccess, fetchAllUsersFailure } = users.actions;

export default users.reducer;
