import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchFailed, fetchStart, loadingSuccess } from '../helpers';
import { Bus } from '../selectedVessel/types';
import { DeviceManufacturer, Manufacturer, ManufacturerModels, ManufacturersState } from './types';

const manufacturersInitialState: ManufacturersState = {
  manufacturersList: [],
  manufacturerModels: {
    id: null,
    modelsList: [],
  },
  deviceManufacturersList: [],
  busesList: [],
  isLoading: false,
  error: null,
};

const manufacturers = createSlice({
  name: 'manufacturers',
  initialState: manufacturersInitialState,
  reducers: {
    fetchManufacturersStart: fetchStart,
    fetchManufacturersFailure: fetchFailed,
    fetchAllManufacturersSuccess(state, { payload }: PayloadAction<Manufacturer[]>) {
      state.manufacturersList = payload;
      loadingSuccess(state);
    },
    fetchAllDeviceManufacturersSuccess(state, { payload }: PayloadAction<DeviceManufacturer[]>) {
      state.deviceManufacturersList = payload;
      loadingSuccess(state);
    },
    fetchAllBusesSuccess(state, { payload }: PayloadAction<Bus[]>) {
      state.busesList = payload;
      loadingSuccess(state);
    },
    fetchModelsForManufacturerSuccess(state, { payload }: PayloadAction<ManufacturerModels>) {
      state.manufacturerModels = payload;
      loadingSuccess(state);
    },
  },
});

export const manufacturerActions = manufacturers.actions;

export default manufacturers.reducer;
