import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getClientDetails(clientId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/clients/${clientId}/details`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getClientVessels(clientId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/clients/${clientId}/vessels`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getClientNotes(clientId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/myOrganisation/clients/${clientId}/notes`,
    method: 'GET',
  };
  return requestMiddleware(config);
}
