import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getContacts(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/messages/contacts',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getUserGroups(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/messages/groups',
    method: 'GET',
  };
  return requestMiddleware(config);
}
