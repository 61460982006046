import generateErrorObject from 'utils/generateErrorObject';

import { AppThunk } from '../index';
import { localLoader, saveErrorSuccess, updateGeofenceSuccess } from './index';

export const saveError =
  (
    caughtError: any | undefined | null,
    requestData?: any | undefined,
    setLoading?: (state: false) => void,
    handleClose?: (state: false) => void
  ): AppThunk =>
  async dispatch => {
    const error = generateErrorObject(caughtError, requestData);
    setLoading && setLoading(false);
    handleClose && handleClose(false);

    localLoader(dispatch, async () => {
      dispatch(saveErrorSuccess(error));
    });
  };

export interface Geofence {
  vesselId: string;
  radius: number;
  lat: string;
  lon: string;
  delay: number;
}

export const updateGeofence =
  (geofenceUpdate: Geofence | null): AppThunk =>
  async dispatch => {
    localLoader(dispatch, async () => {
      dispatch(updateGeofenceSuccess(geofenceUpdate));
    });
  };
