import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserGroups } from 'api/addressBook';
import { AppThunk } from 'store';
import { fetchFailed, fetchStart } from 'store/helpers';

import { ModuleState, UserBasicData } from '../types';

export interface OrganisationState {
  id: number | undefined;
  name: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  telephone: string | undefined;
  defaultSecurityProfile: string | undefined;
  domain: string | undefined;
  address:
    | {
        firstLine: string | undefined;
        secondLine: string | undefined;
        city: string | undefined;
        state: string | undefined;
        country: string | undefined;
        zipCode: string | undefined;
      }
    | undefined;
  contactEmail: string | undefined;
  contactTelephone: string | undefined;
  contactName: string | undefined;
}

export interface UserType {
  id: number;
  name: string;
}

export interface VesselUser extends UserBasicData {
  userType: UserType;
}

export interface VesselUserGroup {
  id: number;
  name: string;
  users: Array<UserBasicData>;
}

interface VesselUserGroupsState extends ModuleState {
  userGroupsList: VesselUserGroup[];
}

const vesselUserGroupsInitialState: VesselUserGroupsState = {
  userGroupsList: [],
  isLoading: false,
  error: null,
};

const vesselUserGroups = createSlice({
  name: 'vesselUserGroups',
  initialState: vesselUserGroupsInitialState,
  reducers: {
    fetchAllVesselUserGroupsStart: fetchStart,
    fetchAllVesselUserGroupsSuccess(state, { payload }: PayloadAction<VesselUserGroup[]>) {
      state.userGroupsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchAllVesselUserGroupsFailure: fetchFailed,
  },
});

export const {
  fetchAllVesselUserGroupsStart,
  fetchAllVesselUserGroupsSuccess,
  fetchAllVesselUserGroupsFailure,
} = vesselUserGroups.actions;

export default vesselUserGroups.reducer;

export const fetchAllVesselUserGroups = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchAllVesselUserGroupsStart());
    const response = await getUserGroups();
    dispatch(fetchAllVesselUserGroupsSuccess(response.data));
  } catch (err) {
    dispatch(fetchAllVesselUserGroupsFailure(err.toString()));
    return err;
  }
};
