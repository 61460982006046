import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllDealers } from 'api/dealers';
import { AppThunk } from 'store';

import { fetchFailed, fetchStart } from '../helpers';
import { Dealer, ModuleState } from '../types';

interface DealersState extends ModuleState {
  dealersList: Dealer[];
}

const dealersInitialState: DealersState = {
  dealersList: [],
  isLoading: false,
  error: null,
};

const dealers = createSlice({
  name: 'dealers',
  initialState: dealersInitialState,
  reducers: {
    fetchAllDealersStart: fetchStart,
    fetchAllDealersSuccess(state, { payload }: PayloadAction<Dealer[]>) {
      state.dealersList = payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchAllDealersFailure: fetchFailed,
  },
});

export const { fetchAllDealersStart, fetchAllDealersSuccess, fetchAllDealersFailure } =
  dealers.actions;

export default dealers.reducer;

export const fetchAllDealers = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchAllDealersStart());
    const response = await getAllDealers();
    dispatch(fetchAllDealersSuccess(response.data));
  } catch (err) {
    dispatch(fetchAllDealersFailure(err.toString()));
    return err;
  }
};
