import { MyResponse, requestMiddleware } from './request';

export async function getAllCountries(): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/utility/countries',
    method: 'GET',
  });
}

export async function getLanguageOptions(): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/utility/languages',
    method: 'GET',
  });
}

export async function getApiVersion(): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/utility/version',
    method: 'GET',
  });
}
