import { useState } from 'react';

export const useDateTime = (): {
  fromDate: Date | null;
  setFromDate: (date: Date | null) => void;
  toDate: Date | null;
  setToDate: (date: Date | null) => void;
  fromHours: string;
  setFromHours: (hours: string) => void;
  toHours: string;
  setToHours: (hours: string) => void;
  fromMinutes: string;
  setFromMinutes: (minutes: string) => void;
  toMinutes: string;
  setToMinutes: (minutes: string) => void;
  fromTimeZone: string;
  setFromTimeZone: (zone: string) => void;
  toTimeZone: string;
  setToTimeZone: (zone: string) => void;
  selectedFromDateTime: string | null;
  setSelectedFromDateTime: (datetime: string | null) => void;
  selectedToDateTime: string | null;
  setSelectedToDateTime: (datetime: string | null) => void;
  fromDateTimeISO: string | null;
  setFromDateTimeISO: (iso: string | null) => void;
  toDateTimeISO: string | null;
  setToDateTimeISO: (iso: string | null) => void;
  isFromDateOpen: boolean;
  setIsFromDateOpen: (open: boolean) => void;
  isToDateOpen: boolean;
  setIsToDateOpen: (open: boolean) => void;
} => {
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [fromHours, setFromHours] = useState<string>('00');
  const [toHours, setToHours] = useState<string>('00');
  const [fromMinutes, setFromMinutes] = useState<string>('00');
  const [toMinutes, setToMinutes] = useState<string>('00');
  const [fromTimeZone, setFromTimeZone] = useState<string>('Universal');
  const [toTimeZone, setToTimeZone] = useState<string>('Universal');
  const [selectedFromDateTime, setSelectedFromDateTime] = useState<string | null>(null);
  const [selectedToDateTime, setSelectedToDateTime] = useState<string | null>(null);
  const [fromDateTimeISO, setFromDateTimeISO] = useState<string | null>(null);
  const [toDateTimeISO, setToDateTimeISO] = useState<string | null>(null);
  const [isFromDateOpen, setIsFromDateOpen] = useState<boolean>(false);
  const [isToDateOpen, setIsToDateOpen] = useState<boolean>(false);

  return {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    fromHours,
    setFromHours,
    toHours,
    setToHours,
    fromMinutes,
    setFromMinutes,
    toMinutes,
    setToMinutes,
    fromTimeZone,
    setFromTimeZone,
    toTimeZone,
    setToTimeZone,
    selectedFromDateTime,
    setSelectedFromDateTime,
    selectedToDateTime,
    setSelectedToDateTime,
    fromDateTimeISO,
    setFromDateTimeISO,
    toDateTimeISO,
    setToDateTimeISO,
    isFromDateOpen,
    setIsFromDateOpen,
    isToDateOpen,
    setIsToDateOpen,
  };
};
