import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDetails } from 'types/errorHandler';

import { fetchFailed, fetchStart, storeLoader } from '../helpers';
import { MyThunkDispatch } from '../index';
import { ModuleState } from '../types';
import { Geofence } from './actions';

interface LocalState extends ModuleState {
  caughtError: ErrorDetails | null;
  geofenceUpdate: Geofence | null;
  unreachable: boolean;
  unreachableVisible: boolean;
  unreachableCount: number;
}

export const localLoader = (dispatch: MyThunkDispatch, reducer: () => Promise<void>): void => {
  storeLoader(dispatch, reducer, localStart, localFailed);
};

const initialLocal: LocalState = {
  caughtError: null,
  geofenceUpdate: null,
  isLoading: false,
  error: null,
  unreachable: false,
  unreachableVisible: false,
  unreachableCount: 0,
};

const local = createSlice({
  name: 'local',
  initialState: initialLocal,
  reducers: {
    localStart: fetchStart,
    localFailed: fetchFailed,
    saveErrorSuccess(state, { payload }: PayloadAction<ErrorDetails | null>) {
      state.caughtError = payload;
      state.isLoading = false;
      state.error = null;
    },
    updateGeofenceSuccess(state, { payload }: PayloadAction<Geofence | null>) {
      state.geofenceUpdate = payload;
      state.isLoading = false;
      state.error = null;
    },
    connectionFailed(state) {
      state.unreachableCount = state.unreachableCount += 1;
      if (state.unreachableCount > 3) {
        state.unreachable = true;
        state.unreachableVisible = true;
      }
    },
    connected(state) {
      if (state.unreachable) {
        state.unreachableCount = 0;
        state.unreachable = false;
        state.unreachableVisible = false;
      }
    },
    resetUnreachable(state) {
      state.unreachableCount = 0;
      state.unreachableVisible = false;
    },
  },
});

export default local.reducer;

export const {
  localStart,
  localFailed,
  saveErrorSuccess,
  updateGeofenceSuccess,
  connectionFailed,
  connected,
  resetUnreachable,
} = local.actions;
