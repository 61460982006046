import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getBasicVessels(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/basic',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAllModels(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vesselModel',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAllDocumentCategories(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/documentCategories',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselsByModel(vesselModelId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vesselModel/${vesselModelId}/vessels`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postAcceptInvite(vesselId: string, state: boolean): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/acceptInvite/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getPendingInvitations(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/pending',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselModel(vesselModelId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vesselModel/${vesselModelId}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselRoute(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/geofenceRoute`,
    method: 'GET',
  });
}

export async function getVesselLocation(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/location`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postCreateVessel(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/create',
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postCreateVesselFormData(formData: FormData): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/createWithImage',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return requestMiddleware(config);
}

export async function postUpdateVessel(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${data.id}/`,
    method: 'PUT',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postUploadVesselImage(formData: FormData): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/vesselImage',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return requestMiddleware(config);
}

export async function postSendVesselPair(
  pairingCode: string,
  vesselId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/pair?pairingCode=${pairingCode}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postGrantUserVesselAccess(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${data.vesselId}/grant/${data.id}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postAddUserToVessel(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${data.vesselId}/addUser?email=${data.email}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getAllUserTypes(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/userTypes',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postWakeupLink(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/wakeup`,
    method: 'POST',
  };
  return requestMiddleware(config);
}
