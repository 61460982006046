import React, { ReactElement } from 'react';
import { Allowed } from 'store/selectedVessel/types';
import styled from 'styled-components';
import { brandAmber, brandBlue, brandDarkGrey, brandRed } from 'styles/colors';

export const WarningInfo = styled.div<{ fixedHeight?: boolean; row?: boolean }>`
  height: ${({ fixedHeight }) => (fixedHeight ? '40px' : 'fit-content')};
  margin: 10px 0;
  display: flex;
  flex-flow: ${({ row }) => (row ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${brandAmber};
  padding: 10px;
  background: #fdf7e5; // brandAmber with 10% transparency on white background
  color: ${brandDarkGrey};
`;

export const NeutralInfo = styled.div<{ fixedHeight?: boolean; row?: boolean }>`
  height: ${({ fixedHeight }) => (fixedHeight ? '40px' : 'fit-content')};
  margin: 10px 0;
  display: flex;
  flex-flow: ${({ row }) => (row ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${brandDarkGrey};
  padding: 10px;
  background: #fcfcfd; // brandDarkGrey with 4% transparency on white background
  color: ${brandDarkGrey};
`;

export const GeneralInfo = styled.div<{ fixedHeight?: boolean; row?: boolean }>`
  height: ${({ fixedHeight }) => (fixedHeight ? '40px' : 'fit-content')};
  margin: 10px 0;
  display: flex;
  flex-flow: ${({ row }) => (row ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${brandBlue};
  padding: 10px;
  background: #eff1f3; // brandBlue with 10% transparency on white background
  color: ${brandDarkGrey};
`;

export const CriticalInfo = styled.div<{ fixedHeight?: boolean; row?: boolean }>`
  height: ${({ fixedHeight }) => (fixedHeight ? '40px' : 'fit-content')};
  margin: 10px 0;
  display: flex;
  flex-flow: ${({ row }) => (row ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${brandRed};
  padding: 10px;
  background: #f8eff1; // brandRed with 10% transparency on white background
  color: ${brandDarkGrey};
`;

export const ElementsGroup = styled.div`
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid ${brandDarkGrey};
  padding: 10px 4px;
  background: rgba(0, 0, 0, 0.05);
`;

export const InputErrorInfo = styled.div`
  margin: -6px 0 10px 2px;
  text-align: left;
  color: ${brandRed};
  font-size: 14px;
`;

export const InputGeneralInfo = styled.div`
  margin: -6px 0 10px 2px;
  text-align: left;
  color: ${brandBlue};
  font-size: 14px;
`;

export function tickBox(selected: Allowed, disabled?: boolean): ReactElement {
  if (disabled) return tickBoxDisabled();
  switch (selected) {
    case 'NONE':
      return tickBoxEmpty();
    case 'PARTIAL':
      return tickBoxPartial();
    case 'FULL':
      return tickBoxFilled();
  }
}

export function simpleTickBox(selected: boolean, disabled?: boolean): ReactElement {
  if (disabled) return tickBoxDisabled();
  if (selected) {
    return tickBoxFilled();
  } else {
    return tickBoxEmpty();
  }
}

function tickBoxDisabled(): ReactElement {
  return <TickBoxElement style={{ borderColor: brandDarkGrey, cursor: 'unset' }} />;
}

function tickBoxEmpty(): ReactElement {
  return <TickBoxElement />;
}

function tickBoxPartial(): ReactElement {
  return (
    <TickBoxElement style={{ background: brandBlue }}>
      <Cover />
    </TickBoxElement>
  );
}

function tickBoxFilled(): ReactElement {
  return <TickBoxElement style={{ background: brandBlue }} />;
}

const TickBoxElement = styled.div`
  position: relative;
  overflow: hidden;
  width: 20px;
  height: 20px;
  border: 2px solid ${brandBlue};
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
`;

const Cover = styled.div`
  left: -24px;
  top: -4px;
  width: 40px;
  height: 20px;
  background: #fff;
  transform: rotate(-45deg);
  position: absolute;
`;
