import React from 'react';
import styled from 'styled-components';

import { useToast } from '../hooks/useToast';
import { getPositionStyle } from '../styles';
import { ToastPosition } from '../types';
import ToastDisplay from './ToastDisplay';

type Props = {
  position?: ToastPosition;
};

const DEFAULT_OFFSET = 16;

export const ToastWrapper: React.FC<Props> = props => {
  const { position = 'bottom-right' } = props;
  const { toasts, handlers } = useToast();
  return (
    <Container>
      {toasts.map(toast => {
        const toastPosition = toast.position || position;
        const offset = handlers.calculateOffset(toast, {
          reverseOrder: undefined,
          gutter: undefined,
          defaultPosition: position,
        });
        const positionStyle = getPositionStyle(toastPosition, offset);
        return (
          <Wrapper
            key={toast.id}
            id={toast.id}
            onClick={() => {
              handlers.removeToast(toast.id);
            }}
            style={positionStyle}
            onHeightUpdate={handlers.updateHeight}>
            <ToastDisplay toast={toast} />
          </Wrapper>
        );
      })}
    </Container>
  );
};

type WrapperProps = {
  onClick: () => void;
  children: JSX.Element;
  style: React.CSSProperties;
  onHeightUpdate: (toastId: string, height: number) => void;
  id: string;
};

const Wrapper: React.FC<WrapperProps> = props => {
  const { style, onClick, children, onHeightUpdate, id } = props;
  const ref = React.useCallback(
    (el: HTMLElement | null) => {
      if (el) {
        const updateHeight = (): void => {
          const height = el.getBoundingClientRect().height;
          onHeightUpdate(id, height);
        };
        updateHeight();
        new MutationObserver(updateHeight).observe(el, {
          subtree: true,
          childList: true,
          characterData: true,
        });
      }
    },
    [id, onHeightUpdate]
  );
  const selectedText = (): boolean => {
    if (window.getSelection) {
      const selection = window.getSelection();
      if (selection && selection.toString().length > 0) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  return (
    <div
      ref={ref}
      onClick={() => {
        //Only close onClick if text is not selected to allow copy text.
        if (!selectedText()) {
          onClick();
        }
      }}
      style={style}>
      {children}
    </div>
  );
};

const Container = styled.div`
  position: fixed;
  top: ${DEFAULT_OFFSET}px;
  left: ${DEFAULT_OFFSET}px;
  right: ${DEFAULT_OFFSET}px;
  bottom: ${DEFAULT_OFFSET}px;
  pointer-events: none;
  z-index: 99999;
`;
