import { BannerContext } from 'context/BannerContext';
import { ReactElement, useContext } from 'react';
import React from 'react';
import styled from 'styled-components';
import { CriticalInfo, GeneralInfo, NeutralInfo, WarningInfo } from 'styles/containers';

const NewBanner = (): ReactElement => {
  const { type, message, icon, handleClick } = useContext(BannerContext);

  const renderBanner = (): ReactElement | null => {
    switch (type) {
      case 'WARNING':
        return (
          <WarningInfo
            style={{ cursor: handleClick ? 'pointer' : 'default' }}
            onClick={() => handleClick && handleClick()}
            fixedHeight
            row>
            {!!icon && <Icon src={icon} alt="" />}
            {message}
          </WarningInfo>
        );
      case 'CRITICAL':
        return (
          <CriticalInfo
            style={{ cursor: handleClick ? 'pointer' : 'default' }}
            onClick={() => handleClick && handleClick()}
            fixedHeight
            row>
            {!!icon && <Icon src={icon} alt="" />}
            {message}
          </CriticalInfo>
        );
      case 'GENERAL':
        return (
          <GeneralInfo
            style={{ cursor: handleClick ? 'pointer' : 'default' }}
            onClick={() => handleClick && handleClick()}
            fixedHeight
            row>
            {!!icon && <Icon src={icon} alt="" />}
            {message}
          </GeneralInfo>
        );
      case 'NEUTRAL':
        return (
          <NeutralInfo
            style={{ cursor: handleClick ? 'pointer' : 'default' }}
            onClick={() => handleClick && handleClick()}
            fixedHeight
            row>
            {!!icon && <Icon src={icon} alt="" />}
            {message}
          </NeutralInfo>
        );
      default:
        return null;
    }
  };

  return <div>{renderBanner()}</div>;
};

export default NewBanner;

const Icon = styled.img`
  height: 24px;
  margin-right: 10px;
`;
