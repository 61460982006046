import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteMessage,
  getMessageThreads,
  getMessageThreadsStats,
  postMessage,
  postMessageThread,
  putMessageThread,
} from 'api/messages';
import { MyResponse } from 'api/request';
import { AppThunk, store, ThunkResponse } from 'store';
import { fetchFailed, fetchStart } from 'store/helpers';

import { ModuleState, UserBasicData } from '../types';

export interface MessageAttachment {
  id: number;
  fileName: string;
}

export interface Message {
  id: number;
  content: string;
  creationTimeStamp: Date;
  deletionTimeStamp: Date;
  mentioned: boolean;
  read: boolean;
  notification: boolean;
  senderUser: {
    userId: string;
    displayName: string;
  };
  attachments: Array<MessageAttachment>;
}
export interface MessageThread {
  id: number;
  name: string;
  read: boolean;
  lastMessageDate: Date;
  messages: Array<Message>;
  users: UserBasicData[];
  readOnly: boolean;
}

interface MessageThreadsState extends ModuleState {
  messageThreadsList: MessageThread[];
  selectedThread: MessageThread | null;
  unreadCount: number;
  isLoading: boolean;
  error: string | null;
}

const messageThreadsInitialState: MessageThreadsState = {
  messageThreadsList: [],
  selectedThread: null,
  unreadCount: 0,
  isLoading: true,
  error: null,
};

const messageThreads = createSlice({
  name: 'messageThreads',
  initialState: messageThreadsInitialState,
  reducers: {
    messagesSliceReset: () => messageThreadsInitialState,
    fetchAllMessageThreadsStart: fetchStart,
    fetchAllMessageThreadsFailure: fetchFailed,
    fetchAllMessageThreadsSuccess(state, { payload }: PayloadAction<MessageThread[]>) {
      state.messageThreadsList = payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchUnreadThreadsCountSuccess(state, { payload }: PayloadAction<any>) {
      state.unreadCount = payload.unreadCount;
    },
    loadMessageThread(state, { payload }: PayloadAction<MessageThread | null>) {
      state.selectedThread = payload;
    },
  },
});

export const {
  fetchAllMessageThreadsStart,
  fetchAllMessageThreadsSuccess,
  fetchAllMessageThreadsFailure,
  fetchUnreadThreadsCountSuccess,
  loadMessageThread,
  messagesSliceReset,
} = messageThreads.actions;

export default messageThreads.reducer;

export const fetchAllMessageThreads = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    const response = await getMessageThreads();
    dispatch(fetchAllMessageThreadsSuccess(response.data));
  } catch (err) {
    dispatch(fetchAllMessageThreadsFailure(err.toString()));
    return err;
  }
};

export const selectMessageThread =
  (messageThread: MessageThread | null): AppThunk =>
  async dispatch => {
    dispatch(loadMessageThread(messageThread));
  };

export const createMessageForThread =
  (formData: FormData): ThunkResponse<Promise<MyResponse>> =>
  async dispatch => {
    try {
      await postMessage(formData);
      dispatch(fetchAllMessageThreads());
    } catch (err) {
      return err;
    }
  };

export const createMessageThread =
  (userIds: string[]): ThunkResponse<Promise<MyResponse>> =>
  async dispatch => {
    try {
      await postMessageThread(userIds);

      const prevThreads = store.getState().messageThreads.messageThreadsList;

      await dispatch(fetchAllMessageThreads());

      const newThreads = store.getState().messageThreads.messageThreadsList;

      if (prevThreads.length !== newThreads.length) {
        const sortedThreads = [...newThreads];
        sortedThreads.sort((a, b) => b.id - a.id);
        dispatch(selectMessageThread(sortedThreads[0]));
      }
    } catch (err) {
      return err;
    }
  };

export const updateMessageThread =
  (threadId: number, userIds: string[]): ThunkResponse<Promise<MyResponse>> =>
  async dispatch => {
    try {
      await putMessageThread(threadId, userIds);
      dispatch(fetchAllMessageThreads());
    } catch (err) {
      return err;
    }
  };

export const leaveMessageThread =
  (threadId: number, userIds: string[]): ThunkResponse<Promise<MyResponse>> =>
  async dispatch => {
    try {
      await putMessageThread(threadId, userIds);
      dispatch(selectMessageThread(null));
      dispatch(fetchAllMessageThreads());
    } catch (err) {
      return err;
    }
  };

export const fetchUnreadThreadsCount = (): ThunkResponse<Promise<MyResponse>> => async dispatch => {
  try {
    const response = await getMessageThreadsStats();

    dispatch(fetchUnreadThreadsCountSuccess(response.data));
  } catch (err) {
    dispatch(fetchAllMessageThreadsFailure(err.toString()));
    return err;
  }
};

export const removeMessage =
  (formData: FormData): ThunkResponse<Promise<MyResponse>> =>
  async dispatch => {
    const threadId = store.getState().messageThreads.selectedThread?.id;
    if (threadId) {
      try {
        formData.append('threadId', threadId.toString());
        await deleteMessage(formData);
        dispatch(fetchAllMessageThreads());
      } catch (err) {
        return err;
      }
    }
  };

export const addContactToGroup =
  (messageThread: MessageThread, newContacts: string[]): ThunkResponse<Promise<MyResponse>> =>
  async dispatch => {
    const newUsers = [
      ...messageThread.users.map(user => {
        return user.userId;
      }),
      ...newContacts,
    ];

    try {
      await putMessageThread(messageThread.id, newUsers);
      dispatch(fetchAllMessageThreads());
    } catch (err) {
      return err;
    }
  };

export const changeThreadName =
  (messageThread: MessageThread, name: string): ThunkResponse<Promise<MyResponse>> =>
  async dispatch => {
    try {
      await putMessageThread(
        messageThread.id,
        messageThread.users.map(user => user.userId),
        name
      );
      dispatch(fetchAllMessageThreads());
    } catch (err) {
      return err;
    }
  };
