import 'moment-timezone';

import moment from 'moment';

export function timezone(date: Date): string {
  return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
}

export function timestamp(
  date: Date,
  format: 'FULL' | 'DATE' | 'TIME' | 'ISO' | string,
  utc?: true,
  offsetSeconds?: number
): string {
  let f: string;
  switch (format) {
    case 'FULL':
      f = 'DD/MM/YYYY, HH:mm:ss';
      break;
    case 'FULL_Z':
      f = 'DD/MM/YYYY, HH:mm:ss z';
      break;
    case 'DATE':
      f = 'DD/MM/YYYY';
      break;
    case 'TIME':
      f = 'HH:mm:ss';
      break;
    case 'TIME_Z':
      f = 'HH:mm:ss z';
      break;
    case 'ISO':
      f = '';
      break;
    default:
      f = format;
      break;
  }
  const momentObject = utc
    ? offsetSeconds
      ? moment.utc(date).utcOffset(offsetSeconds / 60)
      : moment.utc(date)
    : moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

  return momentObject.format(f);
}

export function timePreferenceConversion(time: string, time24: boolean): string {
  if (time24) {
    return time;
  } else {
    return moment(time, ['HH:mm']).format('hh:mm A');
  }
}

export const getFormattedDateForAlerts = (date: Date, timeFormat24Hr: boolean): string => {
  const localDate = timestamp(date, 'DATE');
  const localTime = timestamp(date, 'TIME');
  const tz = timezone(date);
  const utcTime = timestamp(date, 'TIME', true);
  const utcDate = timestamp(date, 'DATE', true);
  if (localDate) {
    const localDay = localDate.slice(0, 2);
    const utcDay = utcDate.slice(0, 2);
    if (localDay !== utcDay) {
      return `${localDate}, ${timePreferenceConversion(
        localTime,
        timeFormat24Hr ?? false
      )} ${tz} (${utcDate}, ${timePreferenceConversion(utcTime, timeFormat24Hr ?? false)} UTC)`;
    } else {
      return `${localDate}, ${timePreferenceConversion(
        localTime,
        timeFormat24Hr ?? false
      )} ${tz} (${timePreferenceConversion(utcTime, timeFormat24Hr ?? false)} UTC)`;
    }
  } else {
    return `${utcDate}, ${timePreferenceConversion(utcTime, timeFormat24Hr ?? false)} UTC`;
  }
};
