// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import ReactLoading from 'react-loading';

import styles from './ContentLoader.module.scss';

type Props = {
  small?: boolean;
  overlay?: boolean;
  inline?: boolean;
  button?: boolean;
  dark?: boolean;
  color?: string;
  fillMode?: boolean;
};

function ContentLoader(props: Props): ReactElement {
  const { small, overlay, inline, button, color, dark } = props;
  return (
    <div
      style={props.fillMode ? { height: '100%', maxHeight: '500px' } : {}}
      className={classNames([
        styles.contentLoader,
        small && styles.contentLoaderSmall,
        overlay && styles.contentLoaderOverlay,
        inline && styles.contentLoaderInline,
        button && styles.contentLoaderButton,
        dark && styles.contentLoaderDark,
      ])}>
      <ReactLoading
        type={'spin'}
        color={color ?? 'black'}
        height={button ? 24 : 50}
        width={button ? 26 : 50}
      />
    </div>
  );
}

export default ContentLoader;
