function windowsVersion(version: string): string {
  let vName: string;
  if (version === 'NT 10.0') vName = '10 or higher';
  else if (version === 'NT 6.3') vName = '8.1';
  else if (version === 'NT 6.2') vName = '8';
  else if (version === 'NT 6.1') vName = '7';
  else vName = '';
  return vName + ` (${version})`;
}

function macOsVersion(version: string): string {
  let vName: string;
  if (version.includes('10.15')) vName = 'Catalina or higher (10.15+) (or iPadOS)';
  else if (version.includes('10.14')) vName = `Mojave (${version})`;
  else if (version.includes('10.13')) vName = `High Sierra (${version})`;
  else if (version.includes('10.12')) vName = `Sierra (${version})`;
  else vName = `OS X (${version})`;
  return vName;
}

export function userAgentOs(uA: string): string {
  let os: string;
  let osOffset: number;

  // Windows
  if ((osOffset = uA.indexOf('Windows')) !== -1) {
    os = 'Windows ' + windowsVersion(uA.substring(osOffset + 8, uA.indexOf(';')));
  }
  // Android
  else if ((osOffset = uA.indexOf('Android')) !== -1) {
    const temp = uA.substring(osOffset + 8);
    os = 'Android ' + temp.substring(0, temp.indexOf(';'));
  }
  // iPhone
  else if ((osOffset = uA.indexOf('iPhone OS')) !== -1) {
    os = 'iOS ' + uA.substring(osOffset + 10, uA.indexOf('like')).replaceAll('_', '.');
  }
  // iPad
  else if ((osOffset = uA.indexOf('iPad; CPU OS')) !== -1) {
    os = 'iPadOS ' + uA.substring(osOffset + 13, uA.indexOf('like')).replaceAll('_', '.');
  }
  // macOS
  else if ((osOffset = uA.indexOf('Mac OS X')) !== -1) {
    os = 'macOS ' + macOsVersion(uA.substring(osOffset + 9, uA.indexOf(')')).replaceAll('_', '.'));
  }
  // Ubuntu (linux)
  else if (uA.indexOf('Ubuntu') !== -1) {
    os = 'Linux (Ubuntu)';
  }
  // Fedora (linux)
  else if (uA.indexOf('Fedora') !== -1) {
    os = 'Linux (Fedora)';
  }
  // Linux
  else if (uA.indexOf('Linux') !== -1) {
    os = 'Linux';
  }
  // default
  else os = 'Unknown OS';

  return os;
}
