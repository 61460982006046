import { createSlice } from '@reduxjs/toolkit';

const unitMetricState = {
  unitName: 'metric',
};

const unitImperialState = {
  unitName: 'imperial',
};

const unitPreference = createSlice({
  name: 'unit',
  initialState: {
    unitName: 'metric',
  },
  reducers: {
    toggleUnit: state => {
      if (state.unitName === 'metric') {
        return unitImperialState;
      }
      return unitMetricState;
    },
  },
});

export default unitPreference.reducer;

export const { toggleUnit } = unitPreference.actions;
