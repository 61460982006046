import React, { useEffect, useState } from 'react';

import styles from './TextArea.module.scss';

type Props = {
  error?: boolean;
  placeholder?: string;
  prefilled?: boolean;
  short?: boolean;
  width?: string;
  data?: string;
  height?: string;
} & React.ComponentProps<'textarea'>;

const TextAreaInputBeta = React.forwardRef((props: Props, ref: React.Ref<HTMLTextAreaElement>) => {
  const { placeholder, prefilled, short, width, data, height, ...inputProps } = props;
  const [inputState, setInputState] = useState(false);
  const [inputPrefilled, setInputPrefilled] = useState(prefilled);
  const [, setValue] = useState(data);

  useEffect(() => {
    setValue(data);
  }, [data]);

  let length: string;
  short ? (length = '180px') : width ? (length = width) : (length = '360px');

  return (
    <div className={styles.Input} style={{ width: length }}>
      <div
        className={
          inputState
            ? styles.labelActive
            : inputPrefilled || data
            ? styles.labelInactiveBlank
            : styles.labelInactive
        }>
        {placeholder}
      </div>
      <textarea
        className={
          inputState
            ? styles.inputActive
            : inputPrefilled || data
            ? styles.inputInactiveBlank
            : styles.inputInactive
        }
        style={{ height: height }}
        onFocus={() => setInputState(true)}
        onBlur={e => {
          if (!e.target.value.length) {
            setInputState(false);
            setInputPrefilled(false);
          }
        }}
        {...inputProps}
        ref={ref}
      />
    </div>
  );
});

export default TextAreaInputBeta;
