export enum SimpleDeviceType {
  MFD,
  Client,
  Cloud,
}

export interface SimpleDeviceLocationResponse {
  name: string;
  statusValue: string;
  devices: SimpleDevice[];
  activeCount: number;
}

export interface SimpleDevice {
  id: number;
  name: string;
  state: string;
  manufacturer: string;
  model: string;
  serial: string;
  system: string;
  subSystem: string;
  controllable: boolean;
  online: boolean;
  displayComponent: string;
  location: string;
  templateName: string;
  warningState: 'CRITICAL' | 'WARNING' | 'NORMAL' | 'WHITE' | 'GREY' | 'TRANSPARENT';
  fields: SimpleDeviceFieldWrapper[];
  metaData: { capacity: number | string };
  friendlyName: string;
}

export interface SimpleDeviceTemplate {
  id: number;
  name: string;
  system: string;
  subSystem: string;
  controllable: boolean;
  displayComponent: string;
  fields: SimpleDeviceFieldWrapper[];
}

export interface SimpleDeviceFieldWrapper {
  id: number;
  controllable: boolean;
  loggable: boolean;
  displayName: string;
  fieldName: string;
  fieldType: string;
  warnLevelLow: number | 'NOT SET';
  warnLevelHigh: number | 'NOT SET';
  criticalLevelLow: number | 'NOT SET';
  criticalLevelHigh: number | 'NOT SET';
  value: string;
  parsedValue: string;
  dpvhName: string;
  dpvhId: number;
  dictionary: Record<string, string>;
  name: string;
  warningState: string;
  deviceOnline: boolean;
  lastSeen: string;
  validData: string;
  unit: string;
  gaugeLow: number;
  gaugeHigh: number;
  displayValue: string;
}

export interface TankGroup {
  items: SimpleDevice[];
  name: string;
  percentage: number;
  tankCount: number;
  totalCapacity: number;
  totalVolume: number;
  warningState: string;
  volumeUnit: string;
}
