import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFailed, fetchStart, loadingSuccess } from 'store/helpers';

import { ContractorsState } from './types';

const contractorsInitialState: ContractorsState = {
  contractorsList: [],
  isLoading: false,
  error: null,
};

const contractors = createSlice({
  name: 'contractors',
  initialState: contractorsInitialState,
  reducers: {
    fetchContractorsStart: fetchStart,
    fetchContractorsFailure: fetchFailed,
    fetchAllContractorsSuccess(state, { payload }: PayloadAction<any[]>) {
      state.contractorsList = payload;
      loadingSuccess(state);
    },
  },
});

export const contractorActions = contractors.actions;

export default contractors.reducer;
