import ToastIcons from 'components/DynamicSVGs/toast';
import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { brandBlue, brandRed, standardWhite } from 'styles/colors';
import { capitalizeFirstLetters } from 'utils/stringUtils';

import { borderRadius } from '../styles';
import { Toast } from '../types';
import ProgressBar from './ProgressBar';

type Props = {
  toast: Toast;
};

export const ToastDisplay: React.FC<Props> = props => {
  const { toast } = props;
  const requestRef = React.useRef<number | null>(null);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    animate();
    return () => {
      requestRef.current && cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const animate = (): void => {
    const now = Date.now();
    const elapsedTime = now - toast.createdAt;
    const elapsedPercentage = (elapsedTime / (toast.duration || 0)) * 100;
    const remainingPercentage = Math.max(0, Math.round(100 - elapsedPercentage));
    setProgress(remainingPercentage);
    if (remainingPercentage > 0) {
      requestRef.current = requestAnimationFrame(animate);
    }
  };

  const backgroundColor =
    toast.type === 'error' ? brandRed : toast.type === 'success' ? brandBlue : standardWhite;

  const theme = {
    backgroundColor: backgroundColor,
    color: toast.type === 'error' ? standardWhite : brandBlue,
    marginLeft: toast.type === 'error' ? 10 : 0,
  };
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <ToastIcons
          type={toast.type.toUpperCase()}
          iconStyle={{ width: 25, marginRight: 5, alignSelf: 'center' }}
          color={backgroundColor}
        />
        <Column>
          {(toast.type === 'error' || toast.type === 'success') && (
            <Title>{capitalizeFirstLetters(toast.type)}</Title>
          )}
          <Wrapper>{toast.message}</Wrapper>
        </Column>
        <ProgressContainer>
          <ProgressBar bgColor={backgroundColor} completed={progress} />
        </ProgressContainer>
      </Container>
    </ThemeProvider>
  );
};

export default ToastDisplay;

const Container = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  color: ${brandBlue};
  line-height: 1.3;
  will-change: transform;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3), 0 3px 3px rgba(0, 0, 0, 0.5);
  width: 300px;
  pointer-events: auto;
  padding: 8px 15px 10px 5px;
  border-radius: ${borderRadius}px;
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Wrapper = styled.span`
  font-size: 14px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;
