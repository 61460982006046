import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestConfig, requestMiddleware } from 'api/request';

const endpoints = {
  getMyOrganisation: 'getMyOrganisation',
  getCurrentUserPerms: 'currentUserPerms',
  getCorporatePermissionContainers: 'getCorporatePermissionContainers',
  postInviteStaffMember: 'inviteStaffMember',
  postSetStaffPausedState: 'setStaffPausedState',
  postEditStaffMember: 'editStaffMember',
  postRemoveStaffMember: 'removeStaffMember',
  postAcceptRelationship: 'acceptRelationship',
  postRemoveRelationship: 'removeRelationship',
  postResendUserInvite: 'resendUserInvite',
};

export const getMyOrganisation = createAsyncThunk(
  `myOrganisation/${endpoints.getMyOrganisation}`,
  async () => {
    const config: RequestConfig = {
      url: '/api/myOrganisation',
      method: 'GET',
    };
    return requestMiddleware(config);
  }
);

export const getCurrentUserPerms = createAsyncThunk(
  `myOrganisation/${endpoints.getCurrentUserPerms}`,
  async () => {
    const config: RequestConfig = {
      url: '/api/myOrganisation/currentUserPerms',
      method: 'GET',
    };
    return requestMiddleware(config);
  }
);

export const getCorporatePermissionContainers = createAsyncThunk(
  `myOrganisation/${endpoints.getCorporatePermissionContainers}`,
  async () => {
    const config: RequestConfig = {
      url: '/api/myOrganisation/permissions',
      method: 'GET',
    };
    return requestMiddleware(config);
  }
);

export const postInviteStaffMember = createAsyncThunk(
  `myOrganisation/${endpoints.postInviteStaffMember}`,
  async (email: string) => {
    const config: RequestConfig = {
      url: `/api/myOrganisation/inviteStaff?email=${email}`,
      method: 'POST',
    };
    return requestMiddleware(config);
  }
);

export const postSetStaffPausedState = createAsyncThunk(
  `myOrganisation/${endpoints.postSetStaffPausedState}`,
  async ({ cpcId, state }: { cpcId: string; state: boolean }) => {
    const config: RequestConfig = {
      url: `/api/myOrganisation/pause/${cpcId}/${state}`,
      method: 'POST',
    };
    return requestMiddleware(config);
  }
);

export const postRemoveStaffMember = createAsyncThunk(
  `myOrganisation/${endpoints.postRemoveStaffMember}`,
  async (permissionId: string) => {
    const config: RequestConfig = {
      url: `/api/myOrganisation/removeStaff/${permissionId}`,
      method: 'POST',
    };
    return requestMiddleware(config);
  }
);

export const postEditStaffMember = createAsyncThunk(
  `myOrganisation/${endpoints.postEditStaffMember}`,
  async (corporatePermissionContainer: any) => {
    const config: RequestConfig = {
      url: '/api/myOrganisation/editStaffMember',
      method: 'POST',
      data: corporatePermissionContainer,
    };
    return requestMiddleware(config);
  }
);

export const postAcceptRelationship = createAsyncThunk(
  `myOrganisation/${endpoints.postAcceptRelationship}`,
  async (relationshipId: number) => {
    const config: RequestConfig = {
      url: `/api/myOrganisation/relationships/${relationshipId}/accept`,
      method: 'POST',
    };
    return requestMiddleware(config);
  }
);

export const postRemoveRelationship = createAsyncThunk(
  `myOrganisation/${endpoints.postRemoveRelationship}`,
  async (relationshipId: number) => {
    const config: RequestConfig = {
      url: `/api/myOrganisation/relationships/${relationshipId}/remove`,
      method: 'DELETE',
    };
    return requestMiddleware(config);
  }
);

export const postResendUserInvite = createAsyncThunk(
  `myOrganisation/${endpoints.postResendUserInvite}`,
  async (cpcId: number) => {
    const config: RequestConfig = {
      url: `/api/myOrganisation/resendInvite?id=${cpcId}`,
      method: 'POST',
    };
    return requestMiddleware(config);
  }
);
