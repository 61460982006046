import warningIcon from 'assets/images/icons/forBanners/warning.svg';
import stateOffDark from 'assets/images/online-dark-grey.png';
import NewBanner from 'components/NewBanner';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MyThunkDispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { selectedVesselSliceReset } from 'store/selectedVessel';
import styled from 'styled-components';
import { NeutralInfo, WarningInfo } from 'styles/containers';
import { timestamp } from 'utils/timestamp';

const InfoBanner = (): ReactElement => {
  const dispatch: MyThunkDispatch = useDispatch();
  const location = useLocation();
  const { selectedVessel } = useSelector((state: RootState) => state.selectedVessel);
  useEffect(() => {
    if (selectedVessel && !location.pathname.includes(selectedVessel.id))
      dispatch(selectedVesselSliceReset());
  }, [location.pathname]);

  if (selectedVessel) {
    if (!selectedVessel.reportingClient) {
      return (
        <NeutralInfo onClick={() => null} fixedHeight row>
          <Icon src={warningIcon} alt="" />
          Vessel not paired to cloud. Pair now to activate your LINKbridge
        </NeutralInfo>
      );
    }
    if (!selectedVessel.vesselSubscription || !selectedVessel.vesselSubscription.valid) {
      return (
        <WarningInfo onClick={() => null} fixedHeight row>
          <Icon src={warningIcon} alt="" />
          {selectedVessel.name} has no active LINKcloud Services. To access additional features
          please renew the LINKcloud Services.
        </WarningInfo>
      );
    } else if (!selectedVessel.online) {
      return (
        <NeutralInfo fixedHeight row>
          <Icon src={stateOffDark} alt="" />
          Vessel is currently offline. Last seen{' '}
          {selectedVessel.lastSeen &&
            timestamp(new Date(selectedVessel.lastSeen), 'FULL', true) + ' UTC'}
          . Displayed information shows last known values and is not up to date.
        </NeutralInfo>
      );
    } else {
      return <NewBanner />;
    }
  }
  return <NewBanner />;
};

export default InfoBanner;

const Icon = styled.img`
  height: 24px;
  margin-right: 10px;
`;
