import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getAllManufacturers(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/company/manufacturers',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAllBuses(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/dataUtils?action=getAllBuses',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getAllDeviceManufacturers(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/company/deviceManufacturers',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getModelsForManufacturer(id: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/company/manufacturers/${id}/models`,
    method: 'GET',
  };
  return requestMiddleware(config);
}
