import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function postLogin(username: string, password: string): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/auth/login',
    method: 'POST',
    data: { username: username, password: password },
  });
}

export async function postCheckSession(): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/auth/checkSession',
    method: 'POST',
  });
}

export async function postForgottenPassword(data: { email: string }): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/register/forgottenPassword?email=${data.email}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postResetPassword(data: {
  email: string;
  authCode: string;
  newPassword: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/register/resetPassword',
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}
