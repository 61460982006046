import { ActionType, dispatch } from './hooks/store';
import { DefaultToastOptions, Message, MessageObj, Toast, ToastOptions, ToastType } from './types';

type ToastHandler = (message: Message, options?: ToastOptions) => string;

const createToast = (message: Message, type: ToastType = 'blank', opts?: ToastOptions): Toast => ({
  createdAt: Date.now(),
  visible: true,
  type,
  message,
  ...opts,
  id: opts?.id || genId(),
});

const createHandler =
  (type?: ToastType): ToastHandler =>
  (message, options) => {
    const toast = createToast(message, type, options);
    dispatch({ type: ActionType.MODIFY_TOAST, toast });
    return toast.id;
  };

const toast = (message: Message, opts?: ToastOptions): string =>
  createHandler('blank')(message, opts);

toast.error = createHandler('error');
toast.success = createHandler('success');
toast.loading = createHandler('loading');
toast.custom = createHandler('custom');

toast.dismiss = (toastId?: string) => {
  dispatch({
    type: ActionType.DISMISS_TOAST,
    toastId,
  });
};

toast.remove = (toastId?: string) => dispatch({ type: ActionType.REMOVE_TOAST, toastId });

toast.promise = <T>(promise: Promise<T>, msgs: MessageObj, opts?: DefaultToastOptions) => {
  const id = toast.loading(msgs.loading, { ...opts, ...opts?.loading });

  promise
    .then(p => {
      toast.success(msgs.success, {
        id,
        ...opts,
        ...opts?.success,
      });
      return p;
    })
    .catch(() => {
      toast.error(msgs.error, {
        id,
        ...opts,
        ...opts?.error,
      });
    });

  return promise;
};

export { toast };

const genId = (() => {
  let count = 0;
  return () => {
    return (++count).toString();
  };
})();
