import { createContext } from 'react';
import { PermissionLevels } from 'store/types';

//TODO: On merge check al current permissions are present in this type and set accordingly
export type VesselDashboardPermissionContext = {
  alertManager: PermissionLevels;
  deviceManager: PermissionLevels;
  documents: PermissionLevels;
  routineManager: PermissionLevels;
  userManager: PermissionLevels;
  securityManager: PermissionLevels;
  billingManager: PermissionLevels;
  auditLogs: PermissionLevels;
  allowDeviceControl: boolean;
  allowEditVessel: boolean;
  allowLocationServices: boolean;
  allowGrantTechnicalAccess: boolean;
  receiveNotifications: boolean;
};

export const initVesselDashboardPermissionContext: VesselDashboardPermissionContext = {
  alertManager: 'DENY',
  auditLogs: 'DENY',
  allowDeviceControl: false,
  allowEditVessel: false,
  allowLocationServices: false,
  allowGrantTechnicalAccess: false,
  deviceManager: 'DENY',
  documents: 'DENY',
  routineManager: 'DENY',
  userManager: 'DENY',
  securityManager: 'DENY',
  billingManager: 'DENY',
  receiveNotifications: false,
};

export const PermissionsContext = createContext<VesselDashboardPermissionContext>(
  initVesselDashboardPermissionContext
);
