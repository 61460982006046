import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStart } from 'store/helpers';
import { UserBasicData } from 'store/types';

import {
  BasicSubscription,
  NavigationElement,
  PaymentItem,
  PaymentMethod,
  ProfileState,
  UserPreferences,
  UserProfileDetails,
  VendorCompany,
} from './types';

const profileInitialState: ProfileState = {
  userData: null,
  navigationProfile: [],
  userProfileDetails: null,
  paymentMethods: [],
  paymentItems: [],
  subscriptions: [],
  userPreferences: null,
  isLoading: false,
  error: null,
  vendors: [],
  newPaymentMethod: null,
  corporatePerms: null,
};

function fetchFailed(state: ProfileState, action: PayloadAction<string>): void {
  state.isLoading = false;
  state.error = action.payload;
}

const profile = createSlice({
  name: 'profile',
  initialState: profileInitialState,
  reducers: {
    profileSliceReset: () => profileInitialState,
    fetchProfileStart: fetchStart,
    fetchProfileFailed: fetchFailed,
    fetchNavigationProfileSuccess(state, { payload }: PayloadAction<NavigationElement[]>) {
      state.navigationProfile = payload.sort((a, b) => {
        return a.menuOrder > b.menuOrder ? 0 : -1;
      });
      state.isLoading = false;
      state.error = null;
    },
    fetchUserProfileDetailsSuccess(state, { payload }: PayloadAction<UserProfileDetails>) {
      state.userProfileDetails = payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchPaymentMethodsSuccess(state, { payload }: PayloadAction<PaymentMethod[]>) {
      state.paymentMethods = payload;
      state.error = null;
    },
    fetchPaymentItemsSuccess(state, { payload }: PayloadAction<PaymentItem[]>) {
      state.paymentItems = payload;
      state.error = null;
    },
    fetchSubscriptionsSuccess(state, { payload }: PayloadAction<BasicSubscription[]>) {
      state.subscriptions = payload;
      state.error = null;
    },
    fetchVendorsSuccess(state, { payload }: PayloadAction<VendorCompany[]>) {
      state.vendors = payload;
      state.error = null;
    },
    setUserData(state, { payload }: PayloadAction<UserBasicData>) {
      state.userData = payload;
    },
    setUserPreferences(state, { payload }: PayloadAction<UserPreferences>) {
      state.userPreferences = payload;
    },
    setNewPaymentMethod(state, { payload }: PayloadAction<any>) {
      state.newPaymentMethod = payload;
    },
    setCorporatePerms(state, { payload }: any) {
      state.corporatePerms = payload;
    },
  },
});

export const { setUserData, setUserPreferences, profileSliceReset, setCorporatePerms } =
  profile.actions;

export const profileActions = profile.actions;

export default profile.reducer;
