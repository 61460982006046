import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAllDocumentCategories,
  getAllModels,
  getBasicVessels,
  getPendingInvitations,
  getVesselsByModel,
} from 'api/vessels';
import { AppThunk, MyThunkDispatch, ThunkResponse } from 'store';

import { fetchFailed, fetchStart, loadingSuccess } from '../helpers';
import { ModuleState, VesselModel } from '../types';
import { BasicVessel, VesselDocumentCategory } from './types';

interface VesselsState extends ModuleState {
  vesselsList: BasicVessel[];
  modelsList: VesselModel[];
  vesselByModelList: BasicVessel[];
  unsubscribedVesselsCount: number;
  documentCategories: VesselDocumentCategory[];
  pendingVesselInvitations: BasicVessel[];
}

const vesselsInitialState: VesselsState = {
  vesselsList: [],
  modelsList: [],
  vesselByModelList: [],
  documentCategories: [],
  unsubscribedVesselsCount: 0,
  pendingVesselInvitations: [],
  isLoading: false,
  error: null,
};

const vessels = createSlice({
  name: 'vessels',
  initialState: vesselsInitialState,
  reducers: {
    fetchVesselsStart: fetchStart,
    fetchVesselsFailure: fetchFailed,
    fetchVesselsSuccess: loadingSuccess,
    fetchAllVesselsSuccess(state, { payload }: PayloadAction<BasicVessel[]>) {
      if (payload) {
        state.vesselsList = payload;
      }
    },
    fetchAllModelsSuccess(state, { payload }: PayloadAction<VesselModel[]>) {
      if (payload) {
        state.modelsList = payload;
      }
    },
    fetchVesselByModelSuccess(state, { payload }: PayloadAction<BasicVessel[]>) {
      state.vesselByModelList = payload;
    },
    fetchAllDocumentCategoriesSuccess(state, { payload }: PayloadAction<any[]>) {
      state.documentCategories = payload;
    },
    fetchUnsubscribedVesselsCountSuccess(state, { payload }: PayloadAction<BasicVessel[]>) {
      state.unsubscribedVesselsCount = payload.length;
    },
    fetchPendingVesselInvitationsSuccess(state, { payload }: PayloadAction<BasicVessel[]>) {
      if (payload) {
        state.pendingVesselInvitations = payload;
      }
    },
  },
});

export const {
  fetchVesselsSuccess,
  fetchVesselsStart,
  fetchVesselsFailure,
  fetchAllVesselsSuccess,
  fetchAllModelsSuccess,
  fetchVesselByModelSuccess,
  fetchAllDocumentCategoriesSuccess,
  fetchUnsubscribedVesselsCountSuccess,
  fetchPendingVesselInvitationsSuccess,
} = vessels.actions;

export default vessels.reducer;

const storeLoader = async (
  dispatch: MyThunkDispatch,
  storeReducer: () => Promise<any>
): Promise<any> => {
  try {
    dispatch(fetchVesselsStart());
    return await storeReducer();
  } catch (err) {
    dispatch(fetchVesselsFailure(err.toString()));
    return err;
  }
};

export const fetchAllVessels = (): ThunkResponse<Promise<any>> => async dispatch => {
  return storeLoader(dispatch, async () => {
    const response = await getBasicVessels();
    dispatch(fetchAllVesselsSuccess(response.data));
    dispatch(fetchVesselsSuccess());
    return response;
  });
};

export const fetchVesselsByModel =
  (vesselModelId: string): AppThunk =>
  async dispatch => {
    storeLoader(dispatch, async () => {
      const response = await getVesselsByModel(vesselModelId);
      dispatch(fetchVesselByModelSuccess(response.data));
      dispatch(fetchVesselsSuccess());
    });
  };

export const fetchAllModels = (): AppThunk => async dispatch => {
  storeLoader(dispatch, async () => {
    const response = await getAllModels();
    dispatch(fetchAllModelsSuccess(response.data));
    dispatch(fetchVesselsSuccess());
  });
};

export const fetchAllDocumentCategories = (): AppThunk => async dispatch => {
  storeLoader(dispatch, async () => {
    const response = await getAllDocumentCategories();
    dispatch(fetchAllDocumentCategoriesSuccess(response.data));
    dispatch(fetchVesselsSuccess());
  });
};

export const fetchPendingVesselInvitations = (): ThunkResponse<Promise<any>> => async dispatch => {
  storeLoader(dispatch, async () => {
    const response = await getPendingInvitations();
    dispatch(fetchPendingVesselInvitationsSuccess(response.data));
    dispatch(fetchVesselsSuccess());
  });
};
