import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  PayloadAction,
} from '@reduxjs/toolkit';
import { MyResponse } from 'api/request';
import { MyThunkDispatch } from 'store';

import { ModuleState } from './types';

export function fetchStart(state: ModuleState): void {
  state.isLoading = true;
}

export function loadingSuccess(state: ModuleState): void {
  state.isLoading = false;
  state.error = null;
}

export function fetchFailed(state: ModuleState, action: PayloadAction<string>): void {
  state.isLoading = false;
  state.error = action.payload;
}

export const storeLoader = async (
  dispatch: MyThunkDispatch,
  reducer: () => Promise<void>,
  start: ActionCreatorWithoutPayload<string>,
  fail: ActionCreatorWithPayload<string, string>
): Promise<void> => {
  try {
    dispatch(start());
    await reducer();
  } catch (err) {
    dispatch(fail(err.toString()));
    return err;
  }
};

export async function postActionCreator(
  dispatch: MyThunkDispatch,
  type: string,
  func: any,
  params: any
): Promise<MyResponse> {
  dispatch({ type: `${type}_STARTED` });

  return await func(params)
    .then((response: any) => {
      if (response.ok) {
        dispatch({ type: `${type}_SUCCESS` });
        return response;
      }
    })
    .catch((e: any) => {
      dispatch({ type: `${type}_FAILED`, payload: e });
    });
}
