import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchFailed, fetchStart, loadingSuccess } from '../helpers';
import { ModuleState, Note } from '../types';
import { VesselDevice } from './types';

interface SelectedDeviceState extends ModuleState {
  selectedDevice: VesselDevice | null;
  selectedDeviceAlerts: any | null;
  auditTrail: any | null;
  notes: Note[];
}

const selectedDeviceInitialState: SelectedDeviceState = {
  selectedDevice: null,
  selectedDeviceAlerts: null,
  auditTrail: null,
  isLoading: true,
  notes: [],
  error: null,
};

const selectedDevice = createSlice({
  name: 'selectedDevice',
  initialState: selectedDeviceInitialState,
  reducers: {
    fetchSelectedDeviceStart: fetchStart,
    fetchSelectedDeviceFailure: fetchFailed,
    fetchSelectedDeviceSuccess: loadingSuccess,
    fetchSelectedDeviceInfoSuccess(state, { payload }: PayloadAction<VesselDevice>) {
      state.selectedDevice = payload;
      loadingSuccess(state);
    },
    fetchSelectedDeviceNotesSuccess(state, { payload }: PayloadAction<Note[]>) {
      state.notes = payload;
      loadingSuccess(state);
    },
  },
});

export const selectedDeviceActions = selectedDevice.actions;

export default selectedDevice.reducer;
