import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getClientsForCompany } from 'api/clients';
import { AppThunk } from 'store';

import { fetchFailed, fetchStart, loadingSuccess } from '../helpers';
import { ModuleState } from '../types';

export interface Client {
  clientId: string;
  displayName: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string | null;
  telephone: string;
  mobile: string;
  userId: string;
  pending: boolean;
}

interface ClientsState extends ModuleState {
  clientsList: Client[];
}

const clientsInitialState: ClientsState = {
  clientsList: [],
  isLoading: false,
  error: null,
};

const clients = createSlice({
  name: 'clients',
  initialState: clientsInitialState,
  reducers: {
    fetchClientsStart: fetchStart,
    fetchClientsSuccess: loadingSuccess,
    fetchClientsFailure: fetchFailed,
    fetchClientsForCompanySuccess(state, { payload }: PayloadAction<Client[]>) {
      state.clientsList = payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const {
  fetchClientsStart,
  fetchClientsFailure,
  fetchClientsSuccess,
  fetchClientsForCompanySuccess,
} = clients.actions;

export const loadClientsForCompany = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchClientsStart());
    const response = await getClientsForCompany();
    dispatch(fetchClientsForCompanySuccess(response.data));
  } catch (err) {
    dispatch(fetchClientsFailure(err.toString()));
    return err;
  }
};

export default clients.reducer;
