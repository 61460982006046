import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { store } from 'store';
import { checkSessionFailed } from 'store/account/actions';
import { connected, connectionFailed } from 'store/local';

import RequestLimiter from './limiter';

export type RequestConfig = AxiosRequestConfig;

export interface MyResponse<T = any> extends AxiosResponse {
  ok: boolean;
  status: number;
}

const requestLimiter = new RequestLimiter();

const request = async (config: RequestConfig): Promise<MyResponse> => {
  const containsUrl = requestLimiter.checkRequest(config);
  if (containsUrl) {
    return {
      ok: false,
      config: config as InternalAxiosRequestConfig<any>,
      data: undefined,
      headers: {},
      request: undefined,
      status: 200,
      statusText: '',
    };
  }
  const response = await axios.request(config);
  return {
    ok: response.status >= 200 && response.status < 400,
    ...response,
  };
};

export async function requestMiddleware(config: RequestConfig): Promise<MyResponse> {
  const response = await request(config);
  return response;
}

axios.interceptors.request.use(config => {
  let token = config.headers.Authorization ?? store.getState().account.sessionKey;
  if (token !== null) {
    token = 'Bearer ' + token;
  }

  if (config.url?.includes('openweathermap')) {
    return config;
  }

  if (process.env.NODE_ENV === 'production') {
    const endpoint = config.url?.replace('/api/', '');
    if (config) {
      requestLimiter.addRequest(`${process.env.REACT_APP_API_TARGET}/${endpoint}`);
    }
    return {
      ...config,
      url: `${process.env.REACT_APP_API_TARGET}/${endpoint}`,
      headers: {
        Authorization: token,
      } as AxiosRequestHeaders,
      withCredentials: true,
    };
  } else {
    if (config) {
      requestLimiter.addRequest(config.url);
    }
  }

  return {
    ...config,
    headers: {
      Authorization: token,
    } as AxiosRequestHeaders,
    withCredentials: true,
  };
});

axios.interceptors.response.use(
  response => {
    store.dispatch(connected());
    if (response && response.config) {
      requestLimiter.deleteRequest(response.config.url);
    }
    return response;
  },
  error => {
    const { response } = error;
    if (response && response.config) {
      requestLimiter.deleteRequest(response.config.url);
    }
    if (
      response &&
      (response.status === 502 || response.status === 503 || response.status === 504)
    ) {
      store.dispatch(connectionFailed());
      throw error;
    }
    if (response && response.status === 403) {
      store.dispatch(checkSessionFailed(response.data));
    }

    if (
      response &&
      response.config.url.includes('/checkSession') &&
      response.data?.success !== true
    ) {
      store.dispatch(checkSessionFailed(response.data));
    }
    if (!response && error.request) {
      store.dispatch(connectionFailed());
      throw error;
    }
    throw error;
  }
);

export default request;
