import { useEffect, useState } from 'react';

const useBreakpoint = (): { breakpoint: number } => {
  const [breakpoint, setBreakpoint] = useState<number>(window.innerWidth);
  const resize = (): void => {
    setBreakpoint(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return { breakpoint } as const;
};

export default useBreakpoint;
