import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getMessageThreads(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/messages/threads',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postMessageThread(userIds: string[]): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/messages/threads',
    method: 'POST',
    data: { userIds: userIds },
  };
  return requestMiddleware(config);
}

export async function putMessageThread(
  threadId: number,
  userIds: string[],
  name?: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/messages/threads',
    method: 'PUT',
    data: { threadId: threadId, userIds: userIds, name: name },
  };
  return requestMiddleware(config);
}

export async function postPingThread(threadId: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/messages/ping',
    method: 'POST',
    data: { threadId: threadId },
  };
  return requestMiddleware(config);
}

export async function postMessage(formData: FormData): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/messages',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return requestMiddleware(config);
}

export async function deleteMessage(formData: FormData): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/messages',
    method: 'DELETE',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return requestMiddleware(config);
}

export async function getMessageThreadsStats(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/messages/threadStats',
    method: 'GET',
  };
  return requestMiddleware(config);
}
