import { ErrorDetails } from 'types/errorHandler';

import { MyResponse, requestMiddleware } from './request';

export async function postError(err: ErrorDetails): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/utility/error',
    method: 'POST',
    data: err,
  });
}
